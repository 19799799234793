import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'
//css
import '../../assets/css/CheckOut.css';
//components
import ProductoCheckOut from "./componentes/Productos/ProductoCheckOut";
import Loader from "./layout/Loader"
import * as FormaEntrega from "../../constants/FormaEntrega";

class CheckOut extends Component {

    render() {
        const {pedido} = this.props;
        let idPedido = this.props.match.params.idPedido;
        let Productos = [];
        let total = 0;
        let subTotal = 0;
        if (pedido.detalle_pedido)
            Productos = pedido.detalle_pedido.map((idDetallePedido) => {
                let detallePedido = {};
                if (!idPedido)
                    detallePedido = this.props.detallesPedido.create.detallesPedido[idDetallePedido];
                else
                    detallePedido = this.props.detallesPedido.update.detallesPedido[idDetallePedido];
                //Cálculo de totales
                subTotal += detallePedido.precioUnitario * detallePedido.cantidad;
                total = total + detallePedido.precioUnitario * detallePedido.cantidad;
                return <ProductoCheckOut key={idDetallePedido} detallePedido={detallePedido}
                                         idDetallePedido={idDetallePedido}/>;
            });
        else
            Productos.push(<label key={0} style={{padding: "10px"}}>No hay productos agregados al carrito.</label>)

        // pedido.totalEnvio = pedido.total0;
        if (pedido.idFormaEntrega && pedido.idFormaEntrega == FormaEntrega.ENVIO_A_DOMICILIO_CON_PAGO_EN_DESTINO_ID) {
            total += pedido.totalEnvio ? pedido.totalEnvio : 0;
        }

        let direccionEntrega = this.props.direccionesEntrega.byId.direccionesEntrega[pedido.idDireccionEntrega];
        let moneda = this.props.monedas.update.activo;

        return (
            <section className="checkout">
                <div className="container-limit2">
                    {
                        !idPedido ?
                            <h2 className="titulo-seccion">Revisá tu pedido</h2>
                            :
                            <h2 className="titulo-seccion">Tu pedido está listo!</h2>
                    }

                    {
                        this.props.pedidos.update.isFetching &&
                        <Loader/>
                    }
                    {
                        !this.props.pedidos.update.isFetching &&
                        <div className="detalle-compra">
                            {Productos}
                            <div className="row-totales row-subtotal">
                                <div className="detalle-producto">
                                    <h4>Sub Total</h4>
                                </div>
                                <div className="valor-precio">
                                    <p><small>{moneda ? moneda.simbolo : ""}</small>{`${subTotal.toFixed(2)}`}</p>
                                </div>
                            </div>
                            {
                                pedido.idFormaEntrega && pedido.idFormaEntrega == FormaEntrega.ENVIO_A_DOMICILIO_CON_PAGO_EN_DESTINO_ID && pedido.totalEnvio !== null && pedido.idDireccionEntrega ?
                                    <div className="row-totales row-envio">
                                        <div className="detalle-producto">
                                            <h4>Envío</h4>
                                        </div>
                                        <div className="valor-precio">
                                            {
                                                pedido.totalEnvio === 0 ?
                                                    (direccionEntrega && direccionEntrega.idZonaEntrega) ?
                                                        <p style={{color: "#5fc61a"}}>Gratis</p>
                                                        :
                                                        <p style={{color: "#e33737"}}>A coordinar</p>
                                                    :
                                                    <p><small>{moneda ? moneda.simbolo : ""}</small>{`${pedido.totalEnvio ? pedido.totalEnvio.toFixed(2) : 0}`}</p>

                                            }
                                        </div>
                                    </div>
                                    :
                                    ""
                            }

                            <div className="row-totales row-total">
                                <div className="detalle-producto">
                                    <h4>Total</h4>
                                </div>
                                <div className="valor-precio">
                                    <p><small>{moneda ? moneda.simbolo : ""}</small><span>{`${total.toFixed(2)}`}</span></p>
                                </div>
                            </div>
                        </div>

                    }
                    {/*<button className="btn-confirmar">CONFIRMAR PEDIDO</button>*/}
                </div>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        detallesPedido: state.detallesPedido,
        productos: state.productos,
        comprasComunitarias: state.comprasComunitarias,
        pedidos: state.pedidos,
        direccionesEntrega: state.direccionesEntrega,
        monedas: state.monedas

    };
}

const mapDispatchToProps = (dispatch) => {
    return {}
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckOut));



