import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'

import clone from "lodash/clone";

import '../../../../assets/css/ProductoCarrito.css';
//img
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMinusCircle, faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import logoMerca from '../../../../assets/images/logoMerca.png';
//Actions
import {createDetallePedido, deleteDetallePedido} from "../../../../actions/DetallePedidoActions";

class ProductoCheckOut extends Component {

    handleAgregarCantidad(idProducto) {
        var detalle = clone(this.props.detallesPedido.create.detallesPedido[idProducto + "-"]);
        let producto = this.props.productos.byId.productos[idProducto];

        //Cantidad
        let nuevo = {};
        if (!detalle) {
            detalle = {};
            detalle["idProducto"] = idProducto;
            detalle["cantidad"] = 1;
        } else {
            detalle.cantidad = detalle.cantidad + 1;
        }

        //Cálculo de precio
        let precioProductoMoneda = {};
        let moneda = this.props.monedas.update.activo;
        if (moneda && moneda.id)
            precioProductoMoneda = producto.precios_monedas.find((precio) => {
                return precio.idMoneda === moneda.id
            })
        detalle.precioUnitario = precioProductoMoneda && precioProductoMoneda.valor ? precioProductoMoneda.valor : producto.precio;

        nuevo[idProducto + "-"] = detalle;

        this.props.createDetallePedido(nuevo);
    }

    handleQuitarCantidad(idProducto) {
        var detalle = clone(this.props.detallesPedido.create.detallesPedido[this.props.idDetallePedido]);
        if (detalle) {
            if (detalle.cantidad > 1) {
                var cambio = {};
                detalle.cantidad = detalle.cantidad - 1;
                cambio[this.props.idDetallePedido] = detalle;
                this.props.createDetallePedido(cambio);
            } else {
                var cambio = {};
                cambio[this.props.idDetallePedido] = detalle;
                this.props.deleteDetallePedido(cambio);
            }
        }
    }

    render() {
        let {detallePedido} = this.props;
        let producto = this.props.productos.byId.productos[detallePedido.idProducto];
        let promocion = this.props.promociones.byId.promociones[detallePedido.idPromocion];
        let idPedido = this.props.match.params.idPedido;
        let presentacion = producto ? producto.presentacion : detallePedido.desDetallePedido;
        let color = {color: "inherit"};

        //Variedad
        producto && producto.caracteristica_producto && producto.caracteristica_producto.map((idCaracteristicaProducto) => {
            let caracteristicaProducto = this.props.caracteristicasProducto.byId.caracteristicasProducto[idCaracteristicaProducto];
            caracteristicaProducto && caracteristicaProducto.variedad_caracteristica && caracteristicaProducto.variedad_caracteristica.some((idVariedadCaracteristica) => {
                let variedadCaracteristica = this.props.variedadesCaracteristica.byId.variedadesCaracteristica[idVariedadCaracteristica];
                return variedadCaracteristica.stock_variedad.some((idStockVariedad) => {
                    let stockVariedad = this.props.stocksVariedad.byId.stocksVariedad[idStockVariedad];
                    let stock = this.props.stocks.byId.stocks[stockVariedad.idStock];
                    if (stockVariedad.idStock == detallePedido.idStock) {
                        presentacion = caracteristicaProducto.nomCaracteristica + ": " + variedadCaracteristica.nomVariedad;
                        return true;
                    }
                    return false;
                });
            });
        });
        if(detallePedido && producto && !detallePedido.idPromocion && producto.cantidadMinimaCompra && producto.cantidadMinimaCompra > 0 && detallePedido.cantidad < producto.cantidadMinimaCompra){
            presentacion = "Cantidad mínima de compra: "+ producto.cantidadMinimaCompra
            color = {color: "red"};
        } else if (detallePedido && !detallePedido.idPromocion && detallePedido.avisoStock === true) {
            presentacion = "Últimos " + producto.stock + " disponibles 🔥";
            color = {color: "red"};
        }
        let moneda = this.props.monedas.update.activo;
        return (
            <div className="row-producto">
                <div className="img-producto">
                    {
                        producto && producto.imgProducto ?
                            <img src={process.env.PUBLIC_URL + "/productos/" + producto.imgProducto} alt=""/>
                            :
                            <img src={logoMerca} alt=""/>
                    }
                </div>
                <div className="detalle-producto">
                    <h4>{producto ? producto.nomProducto : ""}</h4>
                    <h5 style={color}>{presentacion}</h5>
                    <h6>{producto && !detallePedido.idPromocion ? `${moneda ? moneda.simbolo : ""} ${producto.precio}` : ""}</h6>
                    {
                        promocion &&

                        <p className="etiqueta-promo">
                            {promocion.tipoPromocion === "Sin cargo" ? `De regalo ${detallePedido.cantidad}` : promocion.resultadoPromocion}
                        </p>
                    }
                    <p>
                        {
                            (!idPedido && !promocion && !detallePedido.idFormaPago) ?
                                <span onClick={() => this.handleQuitarCantidad(producto.id)}>
                                    <FontAwesomeIcon icon={faMinusCircle}/>
                                </span> : ""
                        }
                        {detallePedido.cantidad == 1 ? `${detallePedido.cantidad} Unidad` : `${detallePedido.cantidad} Unidades`}
                        {
                            (!idPedido && !promocion && !detallePedido.idFormaPago) ?
                                <span onClick={() => this.handleAgregarCantidad(producto.id)}>
                                    <FontAwesomeIcon icon={faPlusCircle}/>
                                </span> : ""
                        }
                    </p>
                </div>
                <div className="precio-producto">
                    <small>{moneda ? moneda.simbolo : ""}</small>
                    <p>{`${(detallePedido.precioUnitario * detallePedido.cantidad).toFixed(2)}`}</p>
                </div>
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {
        detallesPedido: state.detallesPedido,
        productos: state.productos,
        promociones: state.promociones,
        stocks: state.stocks,
        stocksVariedad: state.stocksVariedad,
        variedadesCaracteristica: state.variedadesCaracteristica,
        caracteristicasProducto: state.caracteristicasProducto,
        monedas: state.monedas
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        createDetallePedido: (detalle) => {
            dispatch(createDetallePedido(detalle))
        },
        deleteDetallePedido: (detalle) => {
            dispatch(deleteDetallePedido(detalle))
        }
    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductoCheckOut));


