import {
    INVALIDATE_RECEPCIONESPAGOTP,
    ERROR_RECEPCIONESPAGOTP,
    RECEIVE_RECEPCIONESPAGOTP,
    REQUEST_RECEPCIONESPAGOTP,
    RESET_RECEPCIONESPAGOTP,
    ERROR_RECEPCIONPAGOTP,
    RECEIVE_RECEPCIONPAGOTP,
    REQUEST_RECEPCIONPAGOTP,
    UPDATE_RECEPCIONPAGOTP,
    REQUEST_UPDATE_RECEPCIONPAGOTP,
    SUCCESS_UPDATE_RECEPCIONPAGOTP,
    ERROR_UPDATE_RECEPCIONPAGOTP,
    RESET_UPDATE_RECEPCIONPAGOTP,
    REQUEST_UPDATE_RECEPCIONESPAGOTP,
    SUCCESS_UPDATE_RECEPCIONESPAGOTP,
    ERROR_UPDATE_RECEPCIONESPAGOTP,
    RESET_UPDATE_RECEPCIONESPAGOTP,
    CREATE_RECEPCIONPAGOTP,
    ERROR_CREATE_RECEPCIONPAGOTP,
    REQUEST_CREATE_RECEPCIONPAGOTP,
    RESET_CREATE_RECEPCIONPAGOTP,
    SUCCESS_CREATE_RECEPCIONPAGOTP,
    REQUEST_CREATE_RECEPCIONESPAGOTP,
    SUCCESS_CREATE_RECEPCIONESPAGOTP,
    ERROR_CREATE_RECEPCIONESPAGOTP,
    RESET_CREATE_RECEPCIONESPAGOTP,
    DELETE_RECEPCIONPAGOTP,
    DELETE_CREATE_RECEPCIONPAGOTP,
    DELETE_UPDATE_RECEPCIONPAGOTP,
    REQUEST_DELETE_RECEPCIONPAGOTP,
    SUCCESS_DELETE_RECEPCIONPAGOTP,
    ERROR_DELETE_RECEPCIONPAGOTP,
    RESET_DELETE_RECEPCIONPAGOTP,
    REQUEST_PRINT_RECEPCIONPAGOTP,
    SUCCESS_PRINT_RECEPCIONPAGOTP,
    ERROR_PRINT_RECEPCIONPAGOTP,
    RESET_PRINT_RECEPCIONPAGOTP
} from '../actions/RecepcionPagoTPActions';


import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        recepcionesPagoTP: {},
    }
}

function recepcionesPagoTPById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_RECEPCIONESPAGOTP:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_RECEPCIONESPAGOTP:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_RECEPCIONESPAGOTP:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_RECEPCIONESPAGOTP:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                recepcionesPagoTP: {}
            });
        case RECEIVE_RECEPCIONESPAGOTP:
            let dato = action.recepcionesPagoTP.entities.recepcionesPagoTP;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                recepcionesPagoTP: merge({}, state.recepcionesPagoTP, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_RECEPCIONPAGOTP:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_RECEPCIONPAGOTP:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_RECEPCIONPAGOTP:
            let datoRecepcionPagoTP = action.recepcionPagoTP.entities.recepcionesPagoTP;
            return Object.assign({}, state, {
                recepcionesPagoTP: merge({}, state.recepcionesPagoTP, datoRecepcionPagoTP),
                isFetching: false,
            });
        
        case SUCCESS_DELETE_RECEPCIONPAGOTP:
            let datoRecepcionPagoTPEliminado = action.recepcionPagoTP.entities.recepcionesPagoTP;
            return Object.assign({}, state, {
                recepcionesPagoTP: mergeWith(clone(datoRecepcionPagoTPEliminado), state.recepcionesPagoTP, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_RECEPCIONPAGOTP:
            let datoRecepcionPagoTPCreado = action.recepcionPagoTP.entities.recepcionesPagoTP;
            return Object.assign({}, state, {
                recepcionesPagoTP: mergeWith(clone(datoRecepcionPagoTPCreado), state.recepcionesPagoTP, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_RECEPCIONESPAGOTP:
            let datosRecepcionPagoTPCreado = action.recepcionesPagoTP.entities.recepcionesPagoTP;
                return Object.assign({}, state, {
                    recepcionesPagoTP: mergeWith(clone(datosRecepcionPagoTPCreado), state.recepcionesPagoTP, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_RECEPCIONPAGOTP:
            let datoRecepcionPagoTPActualizado = action.recepcionPagoTP.entities.recepcionesPagoTP;
            return Object.assign({}, state, {
                recepcionesPagoTP: mergeWith(clone(datoRecepcionPagoTPActualizado), state.recepcionesPagoTP, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_RECEPCIONESPAGOTP:
            let datosRecepcionPagoTPActualizado = action.recepcionesPagoTP.entities.recepcionesPagoTP;
                return Object.assign({}, state, {
                    recepcionesPagoTP: mergeWith(clone(datosRecepcionPagoTPActualizado), state.recepcionesPagoTP, (objValue, srcValue) => {return objValue;})
                });

            

            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                recepcionesPagoTP: {}
            });
        default:
            return state
    }
}


function allRecepcionesPagoTP(state = [], action) {
    switch (action.type) {
        case RECEIVE_RECEPCIONESPAGOTP:
            return action.recepcionesPagoTP.result && action.recepcionesPagoTP.result.recepcionesPagoTP ? union(action.recepcionesPagoTP.result.recepcionesPagoTP, state) : (action.recepcionesPagoTP.result ? action.recepcionesPagoTP.result : state) ;
        
        case SUCCESS_CREATE_RECEPCIONPAGOTP:
                   let datoRecepcionPagoTPSCreate = action.recepcionPagoTP.entities.recepcionesPagoTP;
                   let idNuevoSCreate = null;
                   if (Object.values(datoRecepcionPagoTPSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoRecepcionPagoTPSCreate)[0] && Object.values(datoRecepcionPagoTPSCreate)[0].id ? Object.values(datoRecepcionPagoTPSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
        case RESET_RECEPCIONESPAGOTP:
            return [];

            

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalRecepcionesPagoTP(state = null, action) {
    switch (action.type) {
        case RECEIVE_RECEPCIONESPAGOTP:
            return action.recepcionesPagoTP && action.recepcionesPagoTP.result.total ? action.recepcionesPagoTP.result.total : 0;
        case RESET_RECEPCIONESPAGOTP:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_RECEPCIONPAGOTP:
            let dato = action.recepcionPagoTP.entities.recepcionesPagoTP;
            let recepcionPagoTP = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: recepcionPagoTP ? recepcionPagoTP : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_RECEPCIONPAGOTP:
            let idsUpdate = [];
            Object.values(action.recepcionPagoTP).map((recepcionPagoTPUpdate) => {
                if (recepcionPagoTPUpdate && recepcionPagoTPUpdate.id)
                    idsUpdate.push(recepcionPagoTPUpdate.id);
            });
            return merge({}, state, {
                activo: action.recepcionPagoTP,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_RECEPCIONPAGOTP:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_RECEPCIONPAGOTP:
            let datoRecepcionPagoTPActualizado = {};
            if (Object.values(action.recepcionPagoTP.entities.recepcionesPagoTP).length > 0)
                datoRecepcionPagoTPActualizado = Object.values(action.recepcionPagoTP.entities.recepcionesPagoTP)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoRecepcionPagoTPActualizado
            });
        case ERROR_UPDATE_RECEPCIONPAGOTP:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_RECEPCIONESPAGOTP:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_RECEPCIONESPAGOTP:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_RECEPCIONESPAGOTP:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_RECEPCIONPAGOTP:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           

        case DELETE_RECEPCIONPAGOTP:
            let datoRecepcionPagoTPDelete = action.recepcionPagoTP;
            let idsDelete = [];
           Object.values(action.recepcionPagoTP).map((recepcionPagoTPDelete) => {
               if (recepcionPagoTPDelete && recepcionPagoTPDelete.id)
                   idsDelete.push(recepcionPagoTPDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoRecepcionPagoTPDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_RECEPCIONPAGOTP:
                       let datoRecepcionPagoTPDeleteUpdate = action.recepcionPagoTP;
                       let idsDeleteUpdate = [];
                      Object.values(action.recepcionPagoTP).map((recepcionPagoTPDelete) => {
                          if (recepcionPagoTPDelete && recepcionPagoTPDelete.id)
                              idsDeleteUpdate.push(recepcionPagoTPDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoRecepcionPagoTPDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_RECEPCIONPAGOTP:
                    let datoRecepcionPagoTPDeleted = {};
                    if (Object.values(action.recepcionPagoTP.entities.recepcionesPagoTP).length > 0)
                        datoRecepcionPagoTPDeleted = Object.values(action.recepcionPagoTP.entities.recepcionesPagoTP)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoRecepcionPagoTPDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_RECEPCIONPAGOTP:
             let idsCreate = [];
             Object.values(action.recepcionPagoTP).map((recepcionPagoTPCreate) => {
                 if (recepcionPagoTPCreate && recepcionPagoTPCreate.id)
                     idsCreate.push(recepcionPagoTPCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.recepcionPagoTP,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_RECEPCIONPAGOTP:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_RECEPCIONPAGOTP:
            let datoRecepcionPagoTPNuevo = {};
            if (Object.values(action.recepcionPagoTP.entities.recepcionesPagoTP).length > 0)
                datoRecepcionPagoTPNuevo = Object.values(action.recepcionPagoTP.entities.recepcionesPagoTP)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoRecepcionPagoTPNuevo,
                nuevos: []
            });
        case ERROR_CREATE_RECEPCIONPAGOTP:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_RECEPCIONESPAGOTP:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_RECEPCIONESPAGOTP:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_RECEPCIONESPAGOTP:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_RECEPCIONPAGOTP:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             
        case DELETE_RECEPCIONPAGOTP:
           let datoRecepcionPagoTPDelete = action.recepcionPagoTP;
           let idsDelete = [];
           Object.values(action.recepcionPagoTP).map((recepcionPagoTPDelete) => {
               if (recepcionPagoTPDelete && recepcionPagoTPDelete.id)
                   idsDelete.push(recepcionPagoTPDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoRecepcionPagoTPDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_RECEPCIONPAGOTP:
                  let datoRecepcionPagoTPDeleteCreate = action.recepcionPagoTP;
                  let idsDeleteCreate = [];
                  Object.values(action.recepcionPagoTP).map((recepcionPagoTPDelete) => {
                      if (recepcionPagoTPDelete && recepcionPagoTPDelete.id)
                          idsDeleteCreate.push(recepcionPagoTPDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoRecepcionPagoTPDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_RECEPCIONPAGOTP:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.recepcionPagoTP,
                error: null,
            });
        case REQUEST_DELETE_RECEPCIONPAGOTP:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_RECEPCIONPAGOTP:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_RECEPCIONPAGOTP:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_RECEPCIONPAGOTP:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_RECEPCIONPAGOTP:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_RECEPCIONPAGOTP:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_RECEPCIONPAGOTP:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const recepcionesPagoTP = combineReducers({
    byId: recepcionesPagoTPById,
    allIds: allRecepcionesPagoTP,
    update: update,
    create: create,
    totalRecepcionesPagoTP: totalRecepcionesPagoTP,
    delete: deleter,
    print: print
});

export default recepcionesPagoTP;