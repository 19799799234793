import React, {Component} from 'react';
//css
import '../../assets/css/ZonaEntregas.css';
//img
import {ReactComponent as MapMarker} from "../../assets/images/mapMarker.svg"
import {faBusinessTime, faCheck, faMapPin, faTimes, faTruck} from '@fortawesome/free-solid-svg-icons'
//components
import CampoTexto from "./componentes/CampoTexto"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
//constants
import * as TipoAnalitica from "../../constants/TipoAnalitica";
import {connect} from "react-redux";

/* eslint-disable no-undef */
const _ = require("lodash");
const {compose, withProps, lifecycle} = require("recompose");
const {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    Polygon
} = require("react-google-maps");

const {StandaloneSearchBox} = require("react-google-maps/lib/components/places/StandaloneSearchBox");

const MapWithASearchBox = compose(
    withProps({
        googleMapURL: 'https://maps.googleapis.com/maps/api/js?v=3.exp&language=es&region=AR&libraries=geometry,drawing,places&key=AIzaSyANSH-395EpmTpki-222stF4V76Rp8KcZY',
        loadingElement: <div style={{height: `100%`}}/>,
        containerElement: <div className="containerZonaEntrega"
                               style={{display: "flex", flexWrap: "wrap"}}/>,
        mapElement: <div style={{minHeight: "550px"}}/>,
    }),
    lifecycle({
        componentWillMount() {

            const refs = {};


            this.setState({
                bounds: null,
                center: {
                    lat: -32.967879325747944, lng: -60.64363270998001
                },
                markers: [],
                busqueda: "",
                onMapMounted: ref => {
                    refs.map = ref;
                },
                onPolygonMounted: ref => {
                    refs.polygon = ref;
                },
                onMsjHabilitadoMounted: ref => {
                    refs.msjHabilitado = ref;
                },
                onMsjInhabilitadoMounted: ref => {
                    refs.msjInhabilitado = ref;
                },
                onBoundsChanged: () => {
                    this.setState({
                        //bounds: refs.map.getBounds(),
                        //center: refs.map.getCenter(),
                    })
                },
                onSearchBoxMounted: ref => {
                    refs.searchBox = ref;
                },
                onPlacesChanged: async () => {
                    const places = refs.searchBox.getPlaces();
                    const bounds = new google.maps.LatLngBounds();

                    places.forEach(place => {
                        if (place.geometry.viewport) {
                            bounds.union(place.geometry.viewport)
                        } else {
                            bounds.extend(place.geometry.location)
                        }
                    });
                    const nextMarkers = places.map(place => ({
                        position: place.geometry.location,
                    }));
                    const nextCenter = _.get(nextMarkers, '0.position', this.state.center);


                    let encontrado = false;

                    let datos = [];
                    if (places[0])
                        datos = places[0].address_components;
                    let codPostal = null;
                    for (var i = 0; i < datos.length; i++) {
                        for (var j = 0; j < datos[i].types.length; j++) {
                            if (datos[i].types[j] === "postal_code") {
                                codPostal = datos[i].long_name.replace(/^\D+/g, '');
                                break;
                            }
                        }
                        if (codPostal)
                            break;
                    }


                    this.props.zonasEntrega.allIds.some((idZonaEntrega, i) => {
                        let zonaEntrega = this.props.zonasEntrega.byId.zonasEntrega[idZonaEntrega];

                        if (zonaEntrega.limitarPor === "region") {
                            let arrayZona = [];
                            zonaEntrega.cordenadas.split("\n").forEach((coordenadas) => {
                                let coord = coordenadas.split(",");
                                let punto = {};
                                punto.lat = parseFloat(coord[0]);
                                punto.lng = parseFloat(coord[1]);
                                arrayZona.push(punto);
                            });
                            if (zonaEntrega.cordenadas && zonaEntrega.estado === 1) {
                                var bermudaTriangle = new google.maps.Polygon({paths: arrayZona});
                                let r = google.maps.geometry.poly.containsLocation(nextCenter, bermudaTriangle);
                                if (r) {
                                    refs.msjHabilitado.classList.add("habilitado");
                                    refs.msjInhabilitado.classList.remove("habilitado");
                                    if (places[0]) {
                                        this.props.saveCreateAnalitica(places[0].formatted_address, TipoAnalitica.VALIDARDIRECCIONENTREGA_ID);
                                    }
                                    encontrado = true;
                                    return true;
                                }
                                return false;
                            }
                        } else if (zonaEntrega.limitarPor === "codPostal") {
                            let esZona = zonaEntrega.codigosPostales.split(" ").some((codPostalZona) => {
                                return codPostalZona === codPostal;
                            })

                            if (esZona) {
                                encontrado = true;
                                return true;
                            }
                            return false;
                        }
                    });


                    if (encontrado === false) {
                        refs.msjHabilitado.classList.remove("habilitado");
                        refs.msjInhabilitado.classList.add("habilitado");
                        if (places[0]) {
                            this.props.saveCreateAnalitica(places[0].formatted_address, TipoAnalitica.NOVALIDARDIRECCIONENTREGA_ID);
                        }
                    } else {
                        refs.msjHabilitado.classList.add("habilitado");
                        refs.msjInhabilitado.classList.remove("habilitado");
                    }


                    this.setState({
                        center: nextCenter,
                        markers: nextMarkers
                    });

                    refs.map.fitBounds(bounds);
                },
                setState: (value) => {
                    this.setState(value);
                }
            })
        },
    }),
    withScriptjs,
    withGoogleMap
)(props =>
    <div>

        <GoogleMap
            ref={props.onMapMounted}
            defaultZoom={props.Configuracion && props.Configuracion.mapaZoom ? props.Configuracion.mapaZoom : 15}
            center={props.Configuracion && props.Configuracion.mapaLatCenter && props.Configuracion.mapaLngCenter ?
                {
                    "lat": props.Configuracion.mapaLatCenter,
                    "lng": props.Configuracion.mapaLngCenter
                }
                : props.center}
            onBoundsChanged={props.onBoundsChanged}
            defaultOptions={{mapTypeControl: false, disableDefaultUI: true, scrollwheel: false, zoomControl: true}}
        >

            {props.markers.map((marker, index) =>
                <Marker key={index} position={marker.position}/>
            )}

            {
                props.zonasEntrega.allIds.map((idZonaEntrega, i) => {
                    let zonaEntrega = props.zonasEntrega.byId.zonasEntrega[idZonaEntrega];
                    let arrayZona = [];
                    zonaEntrega.cordenadas.split("\n").forEach((coordenadas) => {
                        let coord = coordenadas.split(",");
                        let punto = {};
                        punto.lat = parseFloat(coord[0]);
                        punto.lng = parseFloat(coord[1]);
                        arrayZona.push(punto);
                    });
                    if (zonaEntrega.cordenadas && zonaEntrega.estado === 1)
                        return (
                            <Polygon key={i} path={arrayZona}
                                     ref={(e) => props.onPolygonMounted(e, i)}
                                     defaultOptions={{fillColor: '#66b71e', strokeColor: '#00b722', strokeWeight: 1}}/>
                        )
                })
            }
        </GoogleMap>

        <h2 className="titulo-seccion subtitulo-home">
            VERIFICA TU ZONA DE ENTREGA
        </h2>
        <StandaloneSearchBox
            ref={props.onSearchBoxMounted}
            bounds={props.bounds}
            onPlacesChanged={props.onPlacesChanged}
            defaultOptions={{strictBounds: true, types: ['establishment']}}
            setState={props.setState}
        >
            <form onSubmit={(e) => {
                e.preventDefault();
            }} autoComplete="false">
                <CampoTexto nombre="Dirección (Calle Num. Ciudad País)" img={<MapMarker/>} type="text" color="#2d82b5"
                            name="direccion" value={props.busqueda ? props.busqueda : ""} handleChange={(e) => {
                    props.setState({busqueda: e.target.value})
                }}/>
            </form>
        </StandaloneSearchBox>

        <p className="mensajeLugarEntrega" ref={props.onMsjHabilitadoMounted}>
            <FontAwesomeIcon icon={faCheck} color="#2d82b5"/>
            Has introducido una <b>zona habilitada</b>
        </p>
        <p className="mensajeLugarEntrega" ref={props.onMsjInhabilitadoMounted}>
            <FontAwesomeIcon icon={faTimes} color="#2d82b5"/>
            Aún no llegamos a esa <b> dirección </b>
            <br/>
        </p>
        <br/>
        <br/>
        {
            props.Configuracion && props.Configuracion.direccionEmpresa && props.Configuracion.direccionEmpresa !== "" &&
            <p className="info-horarios">
                <FontAwesomeIcon icon={faMapPin} style={{width: "13px", marginRight: "8px"}}/>
                <span>{props.Configuracion.direccionEmpresa}</span>
            </p>
        }
        {
            props.Configuracion && props.Configuracion.horarioEmpresa && props.Configuracion.horarioEmpresa !== "" &&
            <div className="info-horarios">
                <div style={{marginBottom: "5px"}}>
                    <FontAwesomeIcon icon={faBusinessTime}/>
                    <span> Nuestro horario de atención al público es:</span>
                </div>
                <div dangerouslySetInnerHTML={{__html: (props.Configuracion.horarioEmpresa)}}/>
                <br/>
                <span>Pero podes hacer tu pedido en cualquier horario!</span>
            </div>
        }
        {
            props.Configuracion && props.Configuracion.horarioEntregaEmpresa && props.Configuracion.horarioEntregaEmpresa !== "" &&
            <div className="info-horarios">
                <div style={{marginBottom: "5px"}}>
                    <FontAwesomeIcon icon={faTruck}/>
                    <span> Nuestro horario de entrega de pedidos:</span>
                </div>
                <div dangerouslySetInnerHTML={{__html: (props.Configuracion.horarioEntregaEmpresa)}}/>
            </div>
        }
    </div>
);

class ZonaEntregas extends Component {

    render() {
        const tienda = this.props.tiendas.update.activo;
        return (
            <section className="zona-entregas" id="zonaEntrega">
                <MapWithASearchBox saveCreateAnalitica={this.props.saveCreateAnalitica.bind(this)}
                                   zonasEntrega={this.props.zonasEntrega}
                                   Configuracion={tienda}/>
            </section>

        );
    }
}

function mapStateToProps(state) {
    return {
        tiendas: state.tiendas
    };
}

const mapDispatchToProps = (dispatch) => {
    return {}
};
export default connect(mapStateToProps, mapDispatchToProps)(ZonaEntregas);
