import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'
import clone from "lodash/clone"
import Hammer from "react-hammerjs";
//css
import '../../assets/css/BarraDerecha.css';
import '../../assets/css/ProductoCarrito.css';
//img
import {ReactComponent as Cart} from "../../assets/images/cart.svg";
import {ReactComponent as Arrow} from "../../assets/images/right-arrow.svg";
//components
import ProductoUnitarioCarrito from "./componentes/Productos/ProductoUnitarioCarrito";
//Actions
import {createDetallePedido, deleteDetallePedido} from "../../actions/DetallePedidoActions";
import {fetchProducto} from "../../actions/ProductoActions";
import {fetchPromocionesIfNeeded} from "../../actions/PromocionActions";
import {saveCreateAnalitica} from "../../actions/AnaliticaActions";
//constants
import * as TipoAnalitica from "../../constants/TipoAnalitica";
import promociones from "../../utils/calculoPromociones";

import isEqual from "lodash/isEqual";
import {createPedido} from "../../actions/PedidoActions";

class BarraDerecha extends Component {
    constructor(props) {
        super(props);
        this.detallesPedidoValidados = [];
    }

    componentDidMount() {
        //TODO busco los productos que tenia agregado al carrito para ver si siguen vigentes
        if (this.props.pedidos.create.nuevo.detalle_pedido) {
            this.props.pedidos.create.nuevo.detalle_pedido.forEach((idDetallePedido) => {
                let detallePedido = this.props.detallesPedido.create.detallesPedido[idDetallePedido];
                let detallePedidoCompleto = {};
                detallePedidoCompleto[idDetallePedido] = detallePedido;
                this.props.fetchProducto(detallePedido.idProducto);
            });
        }
        this.props.fetchPromocionesIfNeeded({estado: true})
    }

    componentDidUpdate(prevProps, prevState) {
        const tienda = this.props.tiendas.update.activo;
        //TODO busco borro o actualizo detalle pedido
        if (this.props.auth.loggedIn !== prevProps.auth.loggedIn) {
            this.detallesPedidoValidados = [];
            if (this.props.pedidos.create.nuevo.detalle_pedido) {
                this.props.pedidos.create.nuevo.detalle_pedido.forEach((idDetallePedido) => {
                    let detallePedido = this.props.detallesPedido.create.detallesPedido[idDetallePedido];
                    let detallePedidoCompleto = {};
                    detallePedidoCompleto[idDetallePedido] = detallePedido;
                    this.props.fetchProducto(detallePedido.idProducto);
                });
            }
        }
        if (this.props.monedas.update.activo.id !== prevProps.monedas.update.activo.id) {
            this.detallesPedidoValidados = [];
        }
        let total = 0;
        if (this.props.pedidos.create.nuevo.detalle_pedido && !this.props.promociones.byId.isFetching && !this.props.productos.byId.isFetching) {
            this.props.pedidos.create.nuevo.detalle_pedido.forEach((idDetallePedido) => {
                let detallePedido = clone(this.props.detallesPedido.create.detallesPedido[idDetallePedido]);
                total = total + detallePedido.precioUnitario * detallePedido.cantidad;
                let detallePedidoCompleto = {};
                detallePedidoCompleto[idDetallePedido] = detallePedido;
                let producto = this.props.productos.byId.productos[detallePedido.idProducto];
                if (((!producto && !detallePedido.idPromocion && detallePedido.id !== "fp") || (producto && (producto.publicar === 0 || producto.noVendible === 1) && !detallePedido.idPromocion))) {
                    this.props.deleteDetallePedido(detallePedidoCompleto);
                } else if (!detallePedido.idPromocion && this.detallesPedidoValidados.indexOf(idDetallePedido) === -1 && !detallePedido.idStock && detallePedido.id !== "fp") {

                    let precioProductoMoneda = {};
                    if (this.props.monedas.update.activo && this.props.monedas.update.activo.id)
                        precioProductoMoneda = producto.precios_monedas.find((precio) => {
                            return precio.idMoneda === this.props.monedas.update.activo.id
                        })
                    detallePedidoCompleto[idDetallePedido].precioUnitario = precioProductoMoneda && precioProductoMoneda.valor ? precioProductoMoneda.valor : producto.precio;
                    detallePedidoCompleto[idDetallePedido].avisoStock = false;
                    this.props.createDetallePedido(detallePedidoCompleto);
                    this.detallesPedidoValidados.push(idDetallePedido);
                } else if (detallePedido.idPromocion) {
                    let promocion = this.props.promociones.byId.promociones[detallePedido.idPromocion];
                    if (!promocion)
                        this.props.deleteDetallePedido(detallePedidoCompleto);
                    else {
                        if (promocion.formatoPromocion !== "Código promocional") {
                            let encontrado = false;
                            promocion.producto_destino_promocion.some((idProductoDestino) => {
                                let productoDestinoPromocion = this.props.productosDestinoPromocion.byId.productosDestinoPromocion[idProductoDestino];
                                if (productoDestinoPromocion.idProducto == detallePedido.idProducto) {
                                    encontrado = true;
                                    return true;
                                }
                                return false;
                            });
                            if (encontrado === false)
                                this.props.deleteDetallePedido(detallePedidoCompleto);
                        } else {
                            let cambio = {};
                            promociones.calcularPromocionCodigoPromocional(cambio, promocion, this.props.detallesPedido, this.props.productosDestinoPromocion);
                            console.log(cambio);
                            let iguales = true;
                            Object.keys(cambio).forEach((idDetallePedidoCambio) => {
                                let detallePedidoCambio = cambio[idDetallePedidoCambio];
                                let detallePedidoGuardado = this.props.detallesPedido.create.detallesPedido[idDetallePedidoCambio];
                                if (detallePedidoCambio.idPromocion !== detallePedidoGuardado.idPromocion ||
                                    detallePedidoCambio.idProducto != detallePedidoGuardado.idProducto ||
                                    detallePedidoCambio.precioUnitario !== detallePedidoGuardado.precioUnitario ||
                                    detallePedidoCambio.cantidad !== detallePedidoGuardado.cantidad)
                                    iguales = false;
                            });
                            if (!iguales)
                                this.props.createDetallePedido(cambio);
                        }
                    }
                } else if (producto && producto.caracteristica_producto && producto.caracteristica_producto.length > 0 && (!detallePedido.idStock || this.detallesPedidoValidados.indexOf(idDetallePedido) === -1)) {
                    let detalleStockDisponible = false;
                    producto.caracteristica_producto && producto.caracteristica_producto.map((idCaracteristicaProducto) => {
                        let caracteristicaProducto = this.props.caracteristicasProducto.byId.caracteristicasProducto[idCaracteristicaProducto];
                        caracteristicaProducto && caracteristicaProducto.variedad_caracteristica && caracteristicaProducto.variedad_caracteristica.some((idVariedadCaracteristica) => {
                            let variedadCaracteristica = this.props.variedadesCaracteristica.byId.variedadesCaracteristica[idVariedadCaracteristica];
                            return variedadCaracteristica.stock_variedad.some((idStockVariedad) => {
                                let stockVariedad = this.props.stocksVariedad.byId.stocksVariedad[idStockVariedad];
                                let stock = this.props.stocks.byId.stocks[stockVariedad.idStock];
                                if (detallePedido.idStock && detallePedido.idStock != stock.id)
                                    return false;
                                let stockDisponible = stock.stock;
                                this.props.pedidos.create.nuevo.detalle_pedido.forEach((idDetallePedidoContStock) => {
                                    let detallePedidoContStock = this.props.detallesPedido.create.detallesPedido[idDetallePedidoContStock];
                                    if (detallePedidoContStock.idStock === stock.id && idDetallePedidoContStock != idDetallePedido)
                                        stockDisponible = stockDisponible - detallePedidoContStock.cantidad
                                });
                                if (stockDisponible >= detallePedido.cantidad) {
                                    detalleStockDisponible = true;
                                    let detallePedidoNuevo = this.props.detallesPedido.create.detallesPedido[detallePedido.idProducto + "--" + stockVariedad.idStock];
                                    if (!detallePedidoNuevo) {
                                        detallePedidoNuevo = detallePedido;
                                    } else {
                                        if (idDetallePedido.indexOf("--") == -1)
                                            detallePedidoNuevo.cantidad = detallePedido.cantidad + detallePedidoNuevo.cantidad;
                                    }
                                    detallePedidoNuevo.idStock = stock.id;
                                    detallePedidoNuevo.precioUnitario = parseFloat(variedadCaracteristica.nomVariedad) * producto.precio;

                                    let detallePedidoNuevoCompleto = {};
                                    detallePedidoNuevoCompleto[detallePedido.idProducto + "--" + stockVariedad.idStock] = detallePedidoNuevo;
                                    this.detallesPedidoValidados.push(detallePedido.idProducto + "--" + stockVariedad.idStock);
                                    this.props.createDetallePedido(detallePedidoNuevoCompleto);
                                    if ((detallePedido.idProducto + "--" + stockVariedad.idStock) != idDetallePedido)
                                        this.props.deleteDetallePedido(detallePedidoCompleto);
                                    return true;
                                }
                                return false;
                            });
                        });
                    });

                    if (detalleStockDisponible === false && (producto.caracteristica_producto && this.props.caracteristicasProducto.allIds.length > 0))
                        this.props.deleteDetallePedido(detallePedidoCompleto);

                } else if (detallePedido.id === "fp") {
                    if (this.props.pedidos.create.nuevo.idFormaPago) {
                        let formaPago = this.props.formasPago.byId.formasPago[this.props.pedidos.create.nuevo.idFormaPago];
                        let formaPagoListaPrecio = null;
                        if (!this.props.formasPago.byId.isFetching)
                            if (formaPago && formaPago.forma_pago_lista_precio && formaPago.forma_pago_lista_precio.length === 1) {
                                formaPagoListaPrecio = this.props.formasPagoListasPrecio.byId.formasPagoListasPrecio[formaPago.forma_pago_lista_precio[0]];
                                if (formaPagoListaPrecio.factor != 0) {
                                    let total = 0;
                                    Object.keys(this.props.detallesPedido.create.detallesPedido).forEach((idDetallePedido) => {
                                        let detallePedidoGuardado = this.props.detallesPedido.create.detallesPedido[idDetallePedido];
                                        if (detallePedidoGuardado.id != detallePedido.id)
                                            total = total + detallePedidoGuardado.precioUnitario * detallePedidoGuardado.cantidad;
                                    });
                                    let nuevo = {};
                                    let detallePedidoNuevo = {};
                                    detallePedidoNuevo.id = "fp";
                                    detallePedidoNuevo.cantidad = 1;
                                    detallePedidoNuevo.precioUnitario = ((formaPagoListaPrecio.factor * total) / 100).toFixed(2);
                                    detallePedidoNuevo.idFormaPago = formaPago.id;
                                    detallePedidoNuevo.desDetallePedido = "Pago con " + formaPago.nomFormaPago;
                                    nuevo.fp = detallePedidoNuevo;
                                    if (detallePedidoNuevo.cantidad !== detallePedido.cantidad || detallePedidoNuevo.precioUnitario !== detallePedido.precioUnitario
                                        || detallePedidoNuevo.idFormaPago !== detallePedido.idFormaPago || detallePedidoNuevo.desDetallePedido !== detallePedido.desDetallePedido)
                                        this.props.createDetallePedido(nuevo);
                                } else
                                    this.props.deleteDetallePedido(detallePedidoCompleto);
                            } else {
                                this.props.deleteDetallePedido(detallePedidoCompleto);
                            }
                    } else {
                        this.props.deleteDetallePedido(detallePedidoCompleto);
                    }
                } else if (tienda && tienda.controlarStock === 1 && detallePedido.cantidad > producto.stock) {
                    detallePedidoCompleto[idDetallePedido].cantidad = producto.stock;
                    detallePedidoCompleto[idDetallePedido].avisoStock = true;
                    this.props.createDetallePedido(detallePedidoCompleto);
                }
            });
            //Monto de envio puede cambiar segun total
            if (this.props.pedidos.create.nuevo && this.props.pedidos.create.nuevo.idDireccionEntrega) {
                let direccionEntrega = this.props.direccionesEntrega.byId.direccionesEntrega[this.props.pedidos.create.nuevo.idDireccionEntrega];
                if (direccionEntrega) {
                    let zonaEntrega = this.props.zonasEntrega.byId.zonasEntrega[direccionEntrega.idZonaEntrega];
                    if (zonaEntrega) {
                        let cambio = {};
                        if (zonaEntrega.montoMinimoEnvioGratis && zonaEntrega.montoMinimoEnvioGratis <= total)
                            cambio.totalEnvio = 0;
                        else {
                            let precioZonaEntregaMoneda = {};
                            if (this.props.monedas.update.activo && this.props.monedas.update.activo.id)
                                precioZonaEntregaMoneda = zonaEntrega.preciosMonedas.find((precio) => {
                                    return precio.idMoneda === this.props.monedas.update.activo.id
                                })
                            cambio.totalEnvio = precioZonaEntregaMoneda && precioZonaEntregaMoneda.precio ? precioZonaEntregaMoneda.precio : zonaEntrega.precio;
                        }
                        if (this.props.pedidos.create.nuevo.totalEnvio !== cambio.totalEnvio)
                            this.props.createPedido(cambio)
                    }
                }
            }
        }
    }

    confirmarCompra() {
        this.props.history.push("/datosEntrega");
        this.props.toogleBarraCarrito(false);
        this.props.saveCreateAnalitica(null, TipoAnalitica.COMPRA_ID);
    }

    render() {
        let Productos = [];
        let total = 0;
        let totalObjetivo = 0;
        let totalSinAhorro = 0;
        if (this.props.pedidos.create.nuevo.detalle_pedido) {
            let idDetallesOrdenados = clone(this.props.pedidos.create.nuevo.detalle_pedido).reverse();
            Productos = idDetallesOrdenados.map((idDetallePedido) => {
                let detallePedido = this.props.detallesPedido.create.detallesPedido[idDetallePedido];
                let producto = this.props.productos.byId.productos[detallePedido.idProducto];

                //Cálculo de totales
                total = total + detallePedido.precioUnitario * detallePedido.cantidad;
                totalObjetivo += detallePedido.precioUnitario * detallePedido.cantidad;
                totalSinAhorro += detallePedido.precioUnitario * detallePedido.cantidad;
                return <ProductoUnitarioCarrito key={idDetallePedido} detallePedido={detallePedido}
                                                idDetallePedido={idDetallePedido}/>;


            });
        }

        let estadoBarra = this.props.estado;

        if (window.innerWidth < 714)
            if (estadoBarra) {
                document.body.classList.add("no-scroll");
            } else {
                document.body.classList.remove("no-scroll");
            }


        let moneda = this.props.monedas.update.activo;
        return (
            <Hammer
                onSwipe={() => {
                    this.props.toogleBarraCarrito(false);
                }}
                direction="DIRECTION_RIGHT"
            >
                <section className={`barra-derecha ${estadoBarra === true ? 'activa' : ''}`}>
                    <div className="container-carrito" style={{height: "100%"}}>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            this.confirmarCompra();
                        }}
                              style={{height: "100%", position: "relative"}}>
                            <div className="titulo-carrito">
                                <h6>MI CARRITO <Cart className="cart" style={{width: "20px", height: "20px"}}/></h6>
                                <Arrow onClick={() => this.props.toogleBarraCarrito(false)} className="flecha-derecha"
                                       style={{width: "22px", height: "22px"}}/>
                            </div>
                            <div className="container-productos">
                                {Productos.length !== 0 ? Productos :
                                    <label style={{padding: "10px 10px 0 10px"}}>No hay productos agregados al
                                        carrito.</label>}
                            </div>
                            <div className="container-carrito-total">
                                <div style={{padding: "0 15px 0 15px"}}>
                                    <div className="precio-total">
                                        <p>Total actual</p>
                                        <p>
                                            <small>{moneda ? moneda.simbolo : ""}</small><span>{total ? `${total.toFixed(2)}` : ""}</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="container-botones">
                                    <button type="submit" className="encargar btn-confirmar"
                                            disabled={!this.props.pedidos.create.nuevo.detalle_pedido || this.props.pedidos.create.nuevo.detalle_pedido.length == 0}>
                                        CONFIRMAR COMPRA<span><Cart/></span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </Hammer>
        );
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        detallesPedido: state.detallesPedido,
        productos: state.productos,
        pedidos: state.pedidos,
        promociones: state.promociones,
        productosDestinoPromocion: state.productosDestinoPromocion,
        stocks: state.stocks,
        stocksVariedad: state.stocksVariedad,
        variedadesCaracteristica: state.variedadesCaracteristica,
        caracteristicasProducto: state.caracteristicasProducto,
        formasPago: state.formasPago,
        formasPagoListasPrecio: state.formasPagoListasPrecio,
        direccionesEntrega: state.direccionesEntrega,
        zonasEntrega: state.zonasEntrega,
        tiendas: state.tiendas,
        monedas: state.monedas


    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteDetallePedido: (detalle) => {
            dispatch(deleteDetallePedido(detalle))
        },
        createDetallePedido: (detalle) => {
            dispatch(createDetallePedido(detalle))
        },
        createPedido: (pedido) => {
            dispatch(createPedido(pedido))
        },
        fetchProducto: (idProducto) => {
            dispatch(fetchProducto(idProducto))
        },
        fetchPromocionesIfNeeded: (filtros) => {
            dispatch(fetchPromocionesIfNeeded(filtros))
        },
        saveCreateAnalitica: (analitica, tipo) => {
            dispatch(saveCreateAnalitica(analitica, tipo))
        },
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BarraDerecha));


