import React, {Component} from 'react';

import '../../assets/css/CompraExitosa.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretSquareLeft} from "@fortawesome/free-solid-svg-icons";
import compraExitosa from "../../assets/images/compraExitosa.png";
import history from '../../history';

class CompraExitosa extends Component {

    componentDidMount() {
        //Subo página
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <section className="compra-exitosa">
                <div className="container-limit">
                    <div className="imagen-circular">
                        <img src={compraExitosa} alt=""/>
                    </div>
                    <h2>¡Gracias por tu compra!</h2>
                    <div className="container-botones">
                        <button className="encargar" style={{outline: "none"}} onClick={() => {
                            history.push("/");
                        }}>
                            VOLVER <span><FontAwesomeIcon icon={faCaretSquareLeft} color="white"/></span>
                        </button>
                    </div>
                </div>
            </section>

        );
    }
}

export default CompraExitosa;
