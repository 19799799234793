//api
import pedidos from "../api/pedidos"
//Normalizers
import {normalizeDato, normalizeDatos} from "../normalizers/normalizePedidos";
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
import {resetCreateDetallesPedido} from "./DetallePedidoActions";
import {resetCreateDireccionEntrega} from "./DireccionEntregaActions";
//history
import history from "../history"

import clone from "lodash/clone"


//PEDIDOS
export const REQUEST_PEDIDOS = 'REQUEST_PEDIDOS';
export const RECEIVE_PEDIDOS = 'RECEIVE_PEDIDOS';
export const INVALIDATE_PEDIDOS = 'INVALIDATE_PEDIDOS';
export const ERROR_PEDIDOS = "ERROR_PEDIDOS";
export const RESET_PEDIDOS = "RESET_PEDIDOS";


export function invalidatePedidos() {
    return {
        type: INVALIDATE_PEDIDOS
    }
}

function requestPedidos() {
    return {
        type: REQUEST_PEDIDOS,
    }
}

function receivePedidos(json) {
    return {
        type: RECEIVE_PEDIDOS,
        pedidos: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorPedidos(error) {
    return {
        type: ERROR_PEDIDOS,
        error: error,
    }
}

function fetchPedidos(filtros) {
    return dispatch => {
        dispatch(requestPedidos());
        return pedidos.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receivePedidos(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorPedidos(errorMessages.UNAUTHORIZED_TOKEN));
                        //dispatch(logout());
                        return;
                    default:
                        dispatch(errorPedidos(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchPedidos(state, filtros) {
    const pedidos = state.pedidos.byId;

    if (!pedidos) {
        return true;
        //Todo ver si lo recupera siempre
        //} else if (filtros.id && state.pedidos.allIds.indexOf(filtros.id) == -1) {
    } else if (filtros.id) {
        return true
    } else if (pedidos.isFetching && (!filtros.searchPhase || filtros.searchPhase != "")) {
        return false;
    } else {
        return pedidos.didInvalidate;
    }
}

export function fetchPedidosIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchPedidos(getState(), filtros)) {
            return dispatch(fetchPedidos(filtros))
        }
    }
}

export function resetPedidos() {
    return {
        type: RESET_PEDIDOS
    }
}

//PEDIDO
export const REQUEST_PEDIDO = 'REQUEST_PEDIDO';
export const RECEIVE_PEDIDO = 'RECEIVE_PEDIDO';
export const ERROR_PEDIDO = "ERROR_PEDIDO";


function requestPedido() {
    return {
        type: REQUEST_PEDIDO,
    }
}

function receivePedido(json) {
    return {
        type: RECEIVE_PEDIDO,
        pedido: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorPedido(error) {
    return {
        type: ERROR_PEDIDO,
        error: error,
    }
}

export function fetchPedido(id) {
    return dispatch => {
        dispatch(requestPedido());
        return pedidos.getOne(id)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receivePedido(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorPedido(errorMessages.UNAUTHORIZED_TOKEN));
                        //dispatch(logout());
                        return;
                    default:
                        dispatch(errorPedido(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//CREATE
export const CREATE_PEDIDO = 'CREATE_PEDIDO';
export const REQUEST_CREATE_PEDIDO = "REQUEST_CREATE_PEDIDO";
export const SUCCESS_CREATE_PEDIDO = "SUCCESS_CREATE_PEDIDO";
export const ERROR_CREATE_PEDIDO = "ERROR_CREATE_PEDIDO";
export const RESET_CREATE_PEDIDO = "RESET_CREATE_PEDIDO";

function requestCreatePedido() {
    return {
        type: REQUEST_CREATE_PEDIDO,
    }
}

function receiveCreatePedido(json) {
    return {
        type: SUCCESS_CREATE_PEDIDO,
        receivedAt: Date.now(),
        pedido: json ? normalizeDato(json) : {}
    }
}

export function errorCreatePedido(error) {
    return {
        type: ERROR_CREATE_PEDIDO,
        error: error,
    }
}

export function resetCreatePedido(error) {
    return {
        type: RESET_CREATE_PEDIDO,
        error: error,
    }
}

export function createPedido(pedido) {
    return {
        type: CREATE_PEDIDO,
        pedido
    }
}

export function saveCreatePedido() {
    return (dispatch, getState) => {
        var pedido = clone(getState().pedidos.create.nuevo);
        pedido.detalle_pedido = [];
        //Recorro ids de detalles_pedido_mon del store de pedidos
        if (getState().pedidos.create.nuevo.detalle_pedido)
            getState().pedidos.create.nuevo.detalle_pedido.forEach((idDetalle, index) => {
                //Obtengo el objeto con los datos del detalle
                var detalle = clone(getState().detallesPedido.create.detallesPedido[idDetalle]);
                detalle.nroItem = index;
                pedido.detalle_pedido.push(detalle);
            });
        if (!pedido.idUsuario)
            pedido.usuario = getState().usuarios.create.nuevo;
        pedido.direccion_entrega = getState().direccionesEntrega.byId.direccionesEntrega[pedido.idDireccionEntrega];
        dispatch(requestCreatePedido());
        return pedidos.create(pedido)
            .then(function (response) {
                //Refresco token
                //auth.addToken(response.headers);
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    return response.json()
                }
            })
            .then(
                function (json) {
                    dispatch(receiveCreatePedido());
                    dispatch(resetCreatePedido());
                    dispatch(resetCreateDetallesPedido());
                    dispatch(resetCreateDireccionEntrega())

                    if (json.idPagoMP) {
                        dispatch(receivePedido(json));
                        history.push("/pagoMP/" + json.id)
                    } else if (json.idPagoTP) {
                        // dispatch(receivePedido(json));
                        window.location.href = json.urlPago
                    } else {
                        history.push("/compraExitosa");
                    }

                    // dispatch(invalidateProductos());
                    // dispatch(fetchProductosIfNeeded());
                }
            )
            .catch(function (error) {
                    switch (error.status) {
                        case 401:
                            dispatch(errorCreatePedido(errorMessages.UNAUTHORIZED_TOKEN));
                            dispatch(logout());
                            return;
                        default:
                            try {
                                error.json()
                                    .then((error) => {
                                        if (error.data) {
                                            error.data.id = null;
                                            let pedido = {};
                                            pedido.detalle_pedido = [];
                                            error.data.detallePedido.forEach((detallePedido) => {
                                                console.log("detallePedido", detallePedido);
                                                if (detallePedido.idProducto) {
                                                    if (detallePedido.idPromocion) {
                                                        let promocion = getState().promociones.byId.promociones[detallePedido.idPromocion];
                                                        if ((promocion && promocion.formatoPromocion !== "Código promocional") || !promocion)
                                                            detallePedido.id = detallePedido.idProducto + "-" + detallePedido.idPromocion;
                                                        else
                                                            detallePedido.id = detallePedido.idProducto + "-" + detallePedido.idPromocion + "-cupon";
                                                    } else if (detallePedido.idStock)
                                                        detallePedido.id = detallePedido.idProducto + "--" + detallePedido.idStock;
                                                    else
                                                        detallePedido.id = detallePedido.idProducto + "-";
                                                } else {
                                                    if (detallePedido.idPromocion)
                                                        detallePedido.id = detallePedido.idPromocion + "-cupon";
                                                    if (detallePedido.idFormaPago)
                                                        detallePedido.id = "fp"
                                                }
                                                pedido.detalle_pedido.push(detallePedido);
                                            });
                                            console.log("pedido", pedido);
                                            pedido.direccion_entrega = error.data.direccion_entrega;
                                            pedido.fechaEntrega = error.data.fechaEntrega;
                                            pedido.idDireccionEntrega = error.data.idDireccionEntrega;
                                            pedido.idFormaEntrega = error.data.idFormaEntrega;
                                            pedido.idFormaPago = error.data.idFormaPago;
                                            pedido.idUsuario = error.data.idUsuario;
                                            pedido.totalEnvio = error.data.totalEnvio;
                                            pedido.observaciones = error.data.observaciones;
                                            pedido.idMoneda = error.data.idMoneda;
                                            dispatch(receiveCreatePedido(pedido));
                                            dispatch(requestCreatePedido());
                                        }

                                        if (error.message && error.message != "") {
                                            dispatch(errorCreatePedido(error.message));
                                        } else
                                            dispatch(errorCreatePedido(errorMessages.GENERAL_ERROR));
                                    })
                                    .catch((error) => {
                                        console.log(error)
                                        dispatch(errorCreatePedido(errorMessages.GENERAL_ERROR));
                                    });
                            } catch {
                                dispatch(errorCreatePedido(errorMessages.GENERAL_ERROR));
                            }
                            ;
                            return;
                    }
                }
            );
    }
}

//UPDATE
export const UPDATE_PEDIDO = 'UPDATE_PEDIDO';
export const REQUEST_UPDATE_PEDIDO = "REQUEST_UPDATE_PEDIDO";
export const SUCCESS_UPDATE_PEDIDO = "SUCCESS_UPDATE_PEDIDO";
export const ERROR_UPDATE_PEDIDO = "ERROR_UPDATE_PEDIDO";
export const RESET_UPDATE_PEDIDO = "RESET_UPDATE_PEDIDO";

function requestUpdatePedido() {
    return {
        type: REQUEST_UPDATE_PEDIDO,
    }
}

function receiveUpdatePedido() {
    return {
        type: SUCCESS_UPDATE_PEDIDO,
        receivedAt: Date.now()
    }
}

function errorUpdatePedido(error) {
    return {
        type: ERROR_UPDATE_PEDIDO,
        error: error,
    }
}

export function resetUpdatePedido(error) {
    return {
        type: RESET_UPDATE_PEDIDO,
        error: error,
    }
}

export function updatePedido(pedido) {
    return {
        type: UPDATE_PEDIDO,
        pedido
    }
}

export function changePedido(remito, idPedido) {
    return dispatch => {
        dispatch(invalidatePedidos());
        dispatch(updatePedido(remito, idPedido));
    }
}

export function saveUpdatePedido(pedido) {
    return (dispatch, getState) => {
        dispatch(requestUpdatePedido());
        var pedido = clone(getState().pedidos.update.activo);
        if (!pedido.idDireccionEntrega)
            pedido.direccion_entrega = getState().direccionesEntrega.create.nuevo;
        return pedidos.update(pedido)
            .then(function (response) {
                //Refresco token
                //auth.addToken(response.headers);
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    dispatch(receiveUpdatePedido());
                    dispatch(invalidatePedidos());
                    dispatch(fetchPedidosIfNeeded({id: pedido.id}));
                    history.push("/compraConfirmada");
                }
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdatePedido(errorMessages.UNAUTHORIZED_TOKEN));
                        //dispatch(logout());
                        return;
                    default:
                        dispatch(errorUpdatePedido(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}