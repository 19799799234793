import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'
//lodash
import clone from "lodash/clone";
//img
import logoMerca from "../../../../assets/images/logoMerca.png";
//css
import '../../../../assets/css/ProductoCarrito.css';
//Actions
import {createDetallePedido, deleteDetallePedido} from "../../../../actions/DetallePedidoActions";

class ProductoUnitarioCarrito extends Component {

    handleAgregarCantidad(idProducto) {
        var detalle = clone(this.props.detallesPedido.create.detallesPedido[idProducto + "-"]);
        let producto = this.props.productos.byId.productos[idProducto];

        //Cantidad
        let nuevo = {};
        if (!detalle) {
            detalle = {};
            detalle["idProducto"] = idProducto;
            detalle["cantidad"] = 1;
        } else {
            detalle.cantidad = detalle.cantidad + 1;
        }
        let precioProductoMoneda = {};
        let moneda = this.props.monedas.update.activo;
        if (moneda && moneda.id)
            precioProductoMoneda = producto.precios_monedas.find((precio) => {
                return precio.idMoneda === moneda.id
            })
        detalle.precioUnitario = precioProductoMoneda && precioProductoMoneda.valor ? precioProductoMoneda.valor : producto.precio;

        nuevo[idProducto + "-"] = detalle;

        this.props.createDetallePedido(nuevo);
    }

    handleQuitarCantidad(idProducto) {
        var detalle = clone(this.props.detallesPedido.create.detallesPedido[this.props.idDetallePedido]);
        if (detalle) {
            if (detalle.cantidad > 1) {
                var cambio = {};
                detalle.cantidad = detalle.cantidad - 1;
                cambio[this.props.idDetallePedido] = detalle;
                this.props.createDetallePedido(cambio);
            } else {
                var cambio = {};
                cambio[this.props.idDetallePedido] = detalle;
                this.props.deleteDetallePedido(cambio);
            }
        }
    }

    handleChangeCantidad(e, idProducto) {
        var detalle = clone(this.props.detallesPedido.create.detallesPedido[idProducto + "-"]);
        if (detalle) {
            var cambio = {};
            detalle.cantidad = parseInt(e.target.value);
            //Cálculo de precio
            let producto = this.props.productos.byId.productos[idProducto];
            let precioProductoMoneda = {};
            let moneda = this.props.monedas.update.activo;
            if (moneda && moneda.id)
                precioProductoMoneda = producto.precios_monedas.find((precio) => {
                    return precio.idMoneda === moneda.id
                })
            detalle.precioUnitario = precioProductoMoneda && precioProductoMoneda.valor ? precioProductoMoneda.valor : producto.precio;
            cambio[idProducto + "-"] = detalle;
            this.props.createDetallePedido(cambio);
        }
    }

    deleteProducto(detalle) {
        let cambio = {};
        cambio[this.props.idDetallePedido] = detalle;
        this.props.deleteDetallePedido(cambio);
    }


    render() {
        let {detallePedido} = this.props;
        let producto = this.props.productos.byId.productos[detallePedido.idProducto];
        let promocion = this.props.promociones.byId.promociones[detallePedido.idPromocion];
        let presentacion = producto ? producto.presentacion : detallePedido.desDetallePedido;
        let color = {color: "inherit"};
        if (detallePedido && !detallePedido.idPromocion && producto && producto.cantidadMinimaCompra && producto.cantidadMinimaCompra > 0 && detallePedido.cantidad < producto.cantidadMinimaCompra) {
            presentacion = "Cantidad mínima de compra: " + producto.cantidadMinimaCompra
            color = {color: "red"};
        } else if (detallePedido && !detallePedido.idPromocion && detallePedido.avisoStock === true) {
            presentacion = "Últimos " + producto.stock + " disponibles 🔥";
            color = {color: "red"};
        }

        producto && producto.caracteristica_producto && producto.caracteristica_producto.map((idCaracteristicaProducto) => {
            let caracteristicaProducto = this.props.caracteristicasProducto.byId.caracteristicasProducto[idCaracteristicaProducto];

            caracteristicaProducto && caracteristicaProducto.variedad_caracteristica && caracteristicaProducto.variedad_caracteristica.some((idVariedadCaracteristica) => {
                let variedadCaracteristica = this.props.variedadesCaracteristica.byId.variedadesCaracteristica[idVariedadCaracteristica];
                return variedadCaracteristica.stock_variedad.some((idStockVariedad) => {
                    let stockVariedad = this.props.stocksVariedad.byId.stocksVariedad[idStockVariedad];
                    let stock = this.props.stocks.byId.stocks[stockVariedad.idStock];
                    if (stockVariedad.idStock == detallePedido.idStock) {
                        presentacion = caracteristicaProducto.nomCaracteristica + ": " + variedadCaracteristica.nomVariedad;
                        return true;
                    }
                    return false;
                });
            });
        });
        let moneda = this.props.monedas.update.activo;
        return (
            <div className="producto-carrito producto-individual">
                <div className="producto">
                    {
                        detallePedido && !detallePedido.idPromocion && !detallePedido.idFormaPago &&
                        <div className="cruz-cerrar" onClick={() => this.deleteProducto(detallePedido)}>&times;</div>
                    }
                    <div className="container-img">
                        {
                            producto && producto.imgProducto ?
                                <img src={process.env.PUBLIC_URL + "/productos/" + producto.imgProducto} alt=""/>
                                :
                                <img src={logoMerca} alt=""/>
                        }
                    </div>
                    <div className="container-info-producto">
                        <div className="container-detalle">
                            <div className="nombre-producto">
                                <p>{producto ? producto.nomProducto : ""}</p>
                            </div>
                            <div className="detalle-producto">
                                <p style={color}>{presentacion}</p>
                            </div>
                        </div>
                        {
                            detallePedido && !detallePedido.idPromocion &&
                            <div className="container-precios">
                                <div className="precios-vigentes">
                                    <small>{producto && producto.precio && producto.precio !== 0 && moneda ? moneda.simbolo : ""}</small>
                                    <b>{producto && producto.precio && producto.precio !== 0 ? producto.precio : ""}</b>
                                </div>
                            </div>
                        }
                        <div className="cantidad-precio">
                            {
                                detallePedido && !detallePedido.idPromocion && !detallePedido.idFormaPago &&
                                <div className="container-cantidad">
                                    <div className="btn-restar"
                                         onClick={() => this.handleQuitarCantidad(detallePedido.idProducto)}>&#8722;</div>
                                    <input type="number" value={detallePedido.cantidad}
                                           onChange={(e) => this.handleChangeCantidad(e, detallePedido.idProducto)}/>
                                    <div className="btn-sumar"
                                         onClick={() => this.handleAgregarCantidad(detallePedido.idProducto)}>&#43;</div>
                                </div>
                            }
                            {
                                promocion &&
                                <div>
                                    <p className="etiqueta-promo">
                                        {promocion.tipoPromocion === "Sin cargo" ? `De regalo ${detallePedido.cantidad}` : promocion.resultadoPromocion}
                                    </p>
                                </div>
                            }
                            <div className="container-precio">
                                <small>{detallePedido.precioUnitario && moneda ? moneda.simbolo : ""}</small>
                                <p>{detallePedido.precioUnitario ? (detallePedido.precioUnitario * detallePedido.cantidad).toFixed(2) : ""}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {
        detallesPedido: state.detallesPedido,
        productos: state.productos,
        comprasComunitarias: state.comprasComunitarias,
        promociones: state.promociones,
        stocks: state.stocks,
        stocksVariedad: state.stocksVariedad,
        variedadesCaracteristica: state.variedadesCaracteristica,
        caracteristicasProducto: state.caracteristicasProducto,
        monedas: state.monedas

    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        createDetallePedido: (detalle) => {
            dispatch(createDetallePedido(detalle))
        },
        deleteDetallePedido: (detalle) => {
            dispatch(deleteDetallePedido(detalle))
        }
    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductoUnitarioCarrito));


