import React from 'react';
import {Link} from 'react-router-dom'
//Components
import CarrouselCombo from "../../Carruseles/CarrouselCombo";
//lodash
import clone from "lodash/clone";
import isEqual from "lodash/isEqual"
//css
import '../../../../assets/css/ProductoCarrito.css';
//img
import {ReactComponent as Cart} from "../../../../assets/images/cart.svg";
import {ReactComponent as Happy} from "../../../../assets/images/happy.svg";
import logoMerca from "../../../../assets/images/logoMerca.png";
import * as TipoAnalitica from "../../../../constants/TipoAnalitica";

import isNil from "lodash/isNil"
import {useSelector} from "react-redux";
import {Indicator} from "@jgornati/react-stock-indicator";

export default React.memo(function ProductoUnitario(props) {

    const {producto, promocion, detallePedido, productoOrigenPromocion} = props;
    const tiendas = useSelector(state => state.tiendas);
    const tienda = tiendas.update.activo && tiendas.update.activo.id ? tiendas.update.activo : null;
    const monedas = useSelector(state => state.monedas);
    const moneda = monedas.update.activo && monedas.update.activo.id ? monedas.update.activo : null;

    let precioProductoMoneda = {};
    if (moneda && moneda.id)
        precioProductoMoneda = producto.precios_monedas && producto.precios_monedas.find((precio) => {
            return precio.idMoneda === moneda.id
        })

    let precios = [];
    let precioActual = 0;
    if (isNil(productoOrigenPromocion) || isNil(productoOrigenPromocion.precioPromocional))
        precios = [
            {precio: producto.precioMaximo, leyenda: "Sugerido"}, {
                precio: producto.precioAnterior, leyenda: "En el super"
            }
        ];
    else
        precios = [{precio: (precioProductoMoneda ? precioProductoMoneda.valor : 0), leyenda: "Por unidad"}];
    const precioTachado = precios.find((value) => {
        return (!isNil(value.precio) && value.precio !== 0)
    });
    if (isNil(productoOrigenPromocion) || isNil(productoOrigenPromocion.precioPromocional))
        precioActual = precioProductoMoneda ? precioProductoMoneda.valor : 0;
    else {
        let precioPromocionalMonedaProducto = {};
        if (moneda && moneda.id)
            precioPromocionalMonedaProducto = productoOrigenPromocion.preciosMonedasPromocional.find((precio) => {
                return precio.idMoneda === moneda.id
            })
        precioActual = precioPromocionalMonedaProducto.valor;
    }

    const mobilecheck = () => {
        var check = false;
        (function (a) {
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    }

    const handleAgregarCantidad = (idProducto) => {
        var detalle = clone(detallePedido);

        //Cantidad
        let nuevo = {};
        if (!detalle) {
            detalle = {};
            detalle["idProducto"] = idProducto;
            detalle["cantidad"] = 1;
        } else {
            detalle.cantidad = detalle.cantidad + 1;
        }

        let precioProductoMoneda = {};
        if (moneda && moneda.id)
            precioProductoMoneda = producto.precios_monedas.find((precio) => {
                return precio.idMoneda === moneda.id
            })
        detalle.precioUnitario = precioProductoMoneda && precioProductoMoneda.valor ? precioProductoMoneda.valor : producto.precio;

        nuevo[idProducto + "-"] = detalle;

        props.createDetallePedido(nuevo);

        if (!mobilecheck() && window.innerWidth > 850)
            props.toogleBarraCarrito(true);
    };

    const handleQuitarCantidad = (idProducto) => {
        var detalle = clone(detallePedido);
        if (detalle) {
            if (detalle.cantidad > 1) {
                var cambio = {};
                detalle.cantidad = detalle.cantidad - 1;
                //Cálculo de precio
                let precioProductoMoneda = {};
                if (moneda && moneda.id)
                    precioProductoMoneda = producto.precios_monedas.find((precio) => {
                        return precio.idMoneda === moneda.id
                    })
                detalle.precioUnitario = precioProductoMoneda && precioProductoMoneda.valor ? precioProductoMoneda.valor : producto.precio;

                cambio[idProducto + "-"] = detalle;
                props.createDetallePedido(cambio);
            } else {
                var cambio = {};
                cambio[idProducto + "-"] = detalle;
                props.deleteDetallePedido(cambio);
            }
            if (!mobilecheck() && window.innerWidth > 850)
                props.toogleBarraCarrito(true);
        }
    };

    const handleChangeCantidad = (e, idProducto) => {
        var detalle = clone(detallePedido);
        if (detalle) {
            var cambio = {};
            detalle.cantidad = parseInt(e.target.value);
            //Cálculo de precio
            let precioProductoMoneda = {};
            if (moneda && moneda.id)
                precioProductoMoneda = producto.precios_monedas.find((precio) => {
                    return precio.idMoneda === moneda.id
                })
            detalle.precioUnitario = precioProductoMoneda && precioProductoMoneda.valor ? precioProductoMoneda.valor : producto.precio;
            cambio[idProducto + "-"] = detalle;
            props.createDetallePedido(cambio);
        }
    };

    let Imagenes = [];
    if (producto && producto.imagenes)
        Imagenes = producto.imagenes.map((imagen) => {
            return (
                <div className="item" key={"img" + imagen.id}>
                    <Link to={"/producto/" + producto.id}>
                        <img src={process.env.PUBLIC_URL + "/productos/" + imagen.nombre}
                             alt=""/>
                    </Link>
                </div>
            );
        });

    const handleAnalitica = (analitica, idTipo) => {
        props.createAnalitica(analitica, idTipo)
    };

    let presentacion = producto.presentacion;
    let color = {color: "inherit"};
    if (detallePedido && producto && producto.cantidadMinimaCompra && producto.cantidadMinimaCompra > 0 && detallePedido.cantidad < producto.cantidadMinimaCompra) {
        presentacion = "Cantidad mínima de compra: " + producto.cantidadMinimaCompra
        color = {color: "red"};
    } else if (detallePedido && detallePedido.avisoStock === true) {
        presentacion = "Últimos " + producto.stock + " disponibles 🔥";
        color = {color: "red"};
    }

    return (
        <div className="producto">
            {
                (promocion && promocion.resultadoPromocion) || (producto.tagPrincipal && producto.tagPrincipal !== "") ?
                    <p className="etiqueta-promo"
                       style={{display: promocion && promocion.resultadoPromocionX ? "none" : ""}}>
                        {promocion && promocion.resultadoPromocion ? promocion.resultadoPromocion : producto.tagPrincipal}
                    </p>
                    : null
            }
            {
                promocion && promocion.cantidad && promocion.cantidad !== 0 ?
                    <div className="ribbon-wrapper">
                        <div
                            className="ribbon"
                            style={{fontSize: promocion.resultadoPromocionX ? "14px" : ""}}>
                            {promocion.resultadoPromocionX ? promocion.resultadoPromocionX : "Llevando " + promocion.cantidad}
                        </div>
                    </div>
                    : null
            }
            {/*<div className="container-img">*/}
            {/*    {*/}
            {/*        producto.imgProducto ?*/}
            {/*            <div className="item">*/}
            {/*                <Link to={"/producto/" + producto.id}>*/}
            {/*                    <img src={process.env.PUBLIC_URL + "/productos/" + producto.imgProducto}*/}
            {/*                         alt=""/>*/}
            {/*                </Link>*/}
            {/*            </div>*/}
            {/*            :*/}
            {/*            <img src={logoMerca} alt=""/>*/}
            {/*    }*/}
            {/*</div>*/}
            <div className="container-img carrousel-combo">
                {
                    Imagenes.length > 0 ?
                        Imagenes.length === 1 ?
                            <Link to={"/producto/" + producto.id}>
                                <img src={process.env.PUBLIC_URL + "/productos/" + producto.imgProducto}
                                     alt=""/>
                            </Link>
                            :
                            <CarrouselCombo>
                                {
                                    Imagenes
                                }
                            </CarrouselCombo> :
                        <img src={logoMerca} alt=""/>
                }
            </div>
            <div className="container-detalle">
                <div className="nombre-producto">
                    <p><Link to={"/producto/" + producto.id}>{producto.nomProducto}</Link></p>
                </div>
                <div className="detalle-producto" style={color}>
                    <p>{presentacion}</p>
                </div>
                <div className="detalle-producto">
                    <p style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        Stock:
                        <Indicator
                            style={{width: "20px", marginLeft: "5px"}}
                            stock={producto.stock}
                            config={{
                                low: 3,
                                high: 15
                            }}
                        />
                    </p>
                </div>
            </div>
            <div className="sub-container-botones">
                <div className="container-botones">
                    <div className="precios-vigentes">
                        {
                            precioTachado &&
                            <div className="costo-inicial">
                                <b style={{
                                    fontSize: "unset",
                                    whiteSpace: "nowrap",
                                }}>
                                        <span style={{fontSize: "x-small", marginRight: "5px"}}>
                                            {precioTachado && precioTachado.precio !== 0 && !isNil(precioTachado.precio) ? precioTachado.leyenda : ""}
                                    </span>
                                    <span style={{textDecoration: "line-through"}}>
                                            {(precioTachado.precio !== 0 && !isNil(precioTachado.precio)) ? " " +
                                                (moneda ? moneda.simbolo : "") + precioTachado.precio : ""}
                                        </span>
                                </b>
                            </div>
                        }
                        <div className={"costo-final"}>
                            <p>{precioTachado && precioTachado.precio !== 0 && !isNil(precioTachado.precio) ? Math.round((1 - precioActual / precioTachado.precio) * 100) + "% OFF" : ""}</p>
                            <b>
                                <small>{moneda && precioActual && precioActual !== 0 ? moneda.simbolo : ""}</small>{precioActual && precioActual !== 0 ? precioActual : ""}
                            </b>
                        </div>
                        {
                            !producto.precio &&
                            <div className={"ver-precio"}>
                                <Link className="registrate" to={"/login"}>Ver precio</Link>
                            </div>
                        }
                    </div>
                    {
                        producto.publicar === 1 && producto.noVendible === 0 &&
                        (
                            !detallePedido ?
                                <button className="encargar"
                                        onClick={() => handleAgregarCantidad(producto.id)}>
                                    COMPRAR <span><Cart/></span>
                                </button> :
                                <div className="container-cantidad">
                                    <div className="btn-restar"
                                         onClick={() => handleQuitarCantidad(producto.id)}>&#8722;</div>
                                    <input type="number" value={detallePedido.cantidad}
                                           onChange={(e) => handleChangeCantidad(e, producto.id)}/>
                                    <div className="btn-sumar"
                                         onClick={() => handleAgregarCantidad(producto.id)}>&#43;</div>
                                </div>
                        )
                    }
                    {
                        (producto.publicar === 0 || (producto.publicar === 1 && producto.noVendible === 1)) &&
                        <a className={"button-consultar"} alt="Chatea con nosotros"
                           href={`${tienda ? tienda.whatsappLink : ""}?text=Hola! Quisiera consultar por el producto ${producto.nomProducto} ${producto.codBarra}. Gracias!`}
                           target="_blank"
                           onClick={() => {
                               handleAnalitica(producto.id, TipoAnalitica.CLICK_WHATSAPP_ID);
                           }}
                        >
                            <button className="encargar" disabled={false} style={{height: "100%"}}>
                                Consultar <span><Happy height="15px"/></span>
                            </button>
                        </a>
                    }


                </div>

            </div>
        </div>
    );
}, function (prevProps, nextProps) {
    // console.log(prevProps.mapaDesactivar.size, nextProps.mapaDesactivar.size)
    // console.log(prevProps.value === nextProps.value && prevProps.label === nextProps.label && prevProps.mapaDesactivar.size === nextProps.mapaDesactivar.size)
    return prevProps.producto.id === nextProps.producto.id && prevProps.producto.precio === nextProps.producto.precio && isEqual(prevProps.detallePedido, nextProps.detallePedido) && isEqual(prevProps.promocion, nextProps.promocion);
})




