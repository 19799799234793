import React, {Component} from 'react';
//css
import '../../../assets/css/FormaDePago.css';
//components
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {fetchFormasPagoIfNeeded} from "../../../actions/FormaPagoActions";
import {createDetallePedido} from "../../../actions/DetallePedidoActions";
import {ReactComponent as Cash} from "../../../assets/images/cash.svg";
import {ReactComponent as Transfer} from "../../../assets/images/transfer.svg";
import {ReactComponent as CreditCard} from "../../../assets/images/credit-card.svg";
import {ReactComponent as Hand} from "../../../assets/images/hand.svg";
import {ReactComponent as MercadoPago} from "../../../assets/images/mercadopago.svg";
import {ReactComponent as TodoPago} from "../../../assets/images/todopagoLogo.svg";
import {ReactComponent as PlusPagos} from "../../../assets/images/pluspagos.svg";
import Skelleton from "./Skelleton/Skelleton";


class FormaDePago extends Component {

    constructor(props) {
        super(props);
        this.msjFormaPago = {};
    }

    componentDidMount() {
        this.props.fetchFormasPagoIfNeeded({estado: true});
    }

    handleChangeFormaPago(idFormaPago) {
        this.props.refFormaPago.current.classList.remove("visible");
        this.msjFormaPago.innerHTML = "";
        let e = {target: {id: "idFormaPago", value: idFormaPago}};
        this.props.handleChangePedido(e);
        let formaPago = this.props.formasPago.byId.formasPago[idFormaPago];
        let formaPagoListaPrecio = null;

        if (formaPago && formaPago.forma_pago_lista_precio && formaPago.forma_pago_lista_precio.length === 1) {
            formaPagoListaPrecio = this.props.formasPagoListasPrecio.byId.formasPagoListasPrecio[formaPago.forma_pago_lista_precio[0]];
            if (formaPagoListaPrecio.factor != 0) {

                let total = 0;
                Object.keys(this.props.detallesPedido.create.detallesPedido).forEach((idDetallePedido) => {
                    let detallePedidoGuardado = this.props.detallesPedido.create.detallesPedido[idDetallePedido];
                    total = total + detallePedidoGuardado.precioUnitario * detallePedidoGuardado.cantidad;
                });
                let nuevo = {};
                let detallePedidoNuevo = {};
                detallePedidoNuevo.id = "fp";
                detallePedidoNuevo.cantidad = 1;
                detallePedidoNuevo.idFormaPago = formaPago.id;
                detallePedidoNuevo.desDetallePedido = "Pago con " + formaPago.nomFormaPago;
                detallePedidoNuevo.precioUnitario = ((formaPagoListaPrecio.factor * total) / 100).toFixed(2);
                nuevo.fp = detallePedidoNuevo;
                this.props.createDetallePedido(nuevo);
                this.msjFormaPago.innerHTML = "Se ha actualizado el valor de tu compra.";
            }
        }
    }

    render() {
        let {disabled, pedido, refFormaPago} = this.props;
        let opciones = [];
        let indicaciones = [];

        opciones.push(<option key={0} value={""}>Seleccione la forma de pago</option>);
        this.props.formasPago.allIds.map((idFormaPago, i) => {
            let formaPago = this.props.formasPago.byId.formasPago[idFormaPago];
            indicaciones.push(
                <div key={formaPago.id}>
                    <h4>{formaPago ? formaPago.nomFormaPago : ""}</h4>
                    {
                        formaPago && formaPago.desFormaPago && formaPago.desFormaPago !== "" &&
                        <p dangerouslySetInnerHTML={{__html: formaPago.desFormaPago}}></p>
                    }
                    <br/>
                </div>
            );
            opciones.push(<option key={i + 1}
                                  value={formaPago.id}>{formaPago ? formaPago.nomFormaPago : ""}</option>);
        });

        return (
            <section className="formaDePago">
                <h2 className="titulo-seccion subtitulo-checkout">Forma de pago</h2>
                <div>
                    {indicaciones}
                </div>
                <br/>
                <p className="mensaje" ref={refFormaPago}>Debe seleccionar una forma de pago</p>
                <div className="container-formaPago">
                    {
                        !this.props.formasPago.byId.isFetching &&
                        this.props.formasPago.allIds.map((idFormaPago) => {
                            let formaPago = this.props.formasPago.byId.formasPago[idFormaPago];
                            //Imagen
                            let imagen = <div className="img"><Hand/></div>;
                            if (formaPago)
                                if (formaPago.nomFormaPago.indexOf("Efectivo") !== -1)
                                    imagen = <div className="img"><Cash/></div>;
                                else if (formaPago.nomFormaPago.indexOf("Tarjeta") !== -1)
                                    imagen = <div className="img"><CreditCard/></div>;
                                else if (formaPago.nomFormaPago.indexOf("Transferencia") !== -1)
                                    imagen = <div className="img"><Transfer/></div>;
                                else if (formaPago.nomFormaPago.indexOf("Mercado Pago") !== -1 || formaPago.nomFormaPago.indexOf("Mercado pago") !== -1)
                                    imagen = <div className="img"><MercadoPago/></div>;
                                else if (formaPago.nomFormaPago.indexOf("Todo Pago") !== -1)
                                    imagen = <div className="img"><TodoPago/></div>;
                                else if (formaPago.nomFormaPago.indexOf("Plus Pagos Billetera Santa Fe") !== -1)
                                    imagen = <div className="img"><PlusPagos/></div>;
                            return (
                                <div
                                    key={"fp" + idFormaPago}
                                    className={pedido && pedido.idFormaPago === idFormaPago ? "formaPago active" : "formaPago"}
                                    onClick={() => this.handleChangeFormaPago(idFormaPago)}>
                                    <div>
                                        {
                                            formaPago && !!formaPago.nomFormaPago &&
                                            <div>
                                                {
                                                    imagen
                                                }
                                                <p>{`${formaPago.nomFormaPago}`}</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                    {
                        !!this.props.formasPago.byId.isFetching &&
                        <div
                            key={"fp"}
                            className="formaPago-loading">
                            <Skelleton tipo="direccionEntrega"/>
                        </div>
                    }
                </div>
                <p ref={(e) => this.msjFormaPago = e}></p>
            </section>
        );
    }
}


function mapStateToProps(state) {
    return {
        formasPago: state.formasPago,
        formasPagoListasPrecio: state.formasPagoListasPrecio,
        detallesPedido: state.detallesPedido,

    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchFormasPagoIfNeeded: (filtros) => {
            dispatch(fetchFormasPagoIfNeeded(filtros))
        },
        createDetallePedido: (detalle) => {
            dispatch(createDetallePedido(detalle))
        },
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormaDePago));
