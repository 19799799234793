//api
import recepcionesPagoTPApi from "../api/recepcionesPagoTPApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeRecepcionesPagoTP";
//lodash
import merge from "lodash/merge";
import {errorCreatePedido} from "./PedidoActions";

//RECEPCIONESPAGOTP
export const REQUEST_RECEPCIONESPAGOTP = 'REQUEST_RECEPCIONESPAGOTP';
export const RECEIVE_RECEPCIONESPAGOTP = 'RECEIVE_RECEPCIONESPAGOTP';
export const INVALIDATE_RECEPCIONESPAGOTP = 'INVALIDATE_RECEPCIONESPAGOTP';
export const ERROR_RECEPCIONESPAGOTP = "ERROR_RECEPCIONESPAGOTP";
export const RESET_RECEPCIONESPAGOTP = "RESET_RECEPCIONESPAGOTP";


export function invalidateRecepcionesPagoTP() {
    return {
        type: INVALIDATE_RECEPCIONESPAGOTP
    }
}

function requestRecepcionesPagoTP() {
    return {
        type: REQUEST_RECEPCIONESPAGOTP,
    }
}

function receiveRecepcionesPagoTP(json) {
    return {
        type: RECEIVE_RECEPCIONESPAGOTP,
        recepcionesPagoTP: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorRecepcionesPagoTP(error) {
    return {
        type: ERROR_RECEPCIONESPAGOTP,
        error: error,
    }
}

export function resetRecepcionesPagoTP() {
    return {
        type: RESET_RECEPCIONESPAGOTP
    }
}

export function fetchRecepcionesPagoTP(filtros) {
    return dispatch => {
        dispatch(requestRecepcionesPagoTP());
        return recepcionesPagoTPApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveRecepcionesPagoTP(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorRecepcionesPagoTP(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorRecepcionesPagoTP(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchRecepcionesPagoTP(state) {
    const recepcionesPagoTP = state.recepcionesPagoTP.byId;
    if (!recepcionesPagoTP) {
        return true
    } else if (recepcionesPagoTP.isFetching) {
        return false
    } else {
        return recepcionesPagoTP.didInvalidate;
    }
}

export function fetchRecepcionesPagoTPIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchRecepcionesPagoTP(getState())) {
            return dispatch(fetchRecepcionesPagoTP(filtros))
        }
    }
}


//MODEL
export const REQUEST_RECEPCIONPAGOTP = 'REQUEST_RECEPCIONPAGOTP';
export const RECEIVE_RECEPCIONPAGOTP = 'RECEIVE_RECEPCIONPAGOTP';
export const INVALIDATE_RECEPCIONPAGOTP = 'INVALIDATE_RECEPCIONPAGOTP';
export const ERROR_RECEPCIONPAGOTP = "ERROR_RECEPCIONPAGOTP";
export const RESET_RECEPCIONPAGOTP = "RESET_RECEPCIONPAGOTP";


export function invalidateRecepcionPagoTP() {
    return {
        type: INVALIDATE_RECEPCIONPAGOTP
    }
}

function requestRecepcionPagoTP() {
    return {
        type: REQUEST_RECEPCIONPAGOTP,
    }
}

function receiveRecepcionPagoTP(json) {
    return {
        type: RECEIVE_RECEPCIONPAGOTP,
        recepcionPagoTP: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorRecepcionPagoTP(error) {
    return {
        type: ERROR_RECEPCIONPAGOTP,
        error: error,
    }
}

export function fetchRecepcionPagoTP(idRecepcionPagoTP) {
    return dispatch => {
        dispatch(requestRecepcionPagoTP());
        return recepcionesPagoTPApi.getOne(idRecepcionPagoTP)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveRecepcionPagoTP(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorRecepcionPagoTP(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorRecepcionPagoTP(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//UPDATE MODEL
export const UPDATE_RECEPCIONPAGOTP = 'UPDATE_RECEPCIONPAGOTP';
export const REQUEST_UPDATE_RECEPCIONPAGOTP = "REQUEST_UPDATE_RECEPCIONPAGOTP";
export const SUCCESS_UPDATE_RECEPCIONPAGOTP = "SUCCESS_UPDATE_RECEPCIONPAGOTP";
export const ERROR_UPDATE_RECEPCIONPAGOTP = "ERROR_UPDATE_RECEPCIONPAGOTP";
export const RESET_UPDATE_RECEPCIONPAGOTP = "RESET_UPDATE_RECEPCIONPAGOTP";
export const DELETE_UPDATE_RECEPCIONPAGOTP = "DELETE_UPDATE_RECEPCIONPAGOTP";

function requestUpdateRecepcionPagoTP() {
    return {
        type: REQUEST_UPDATE_RECEPCIONPAGOTP,
    }
}

function receiveUpdateRecepcionPagoTP(recepcionPagoTP) {
    return {
        type: SUCCESS_UPDATE_RECEPCIONPAGOTP,
        receivedAt: Date.now(),
        recepcionPagoTP: normalizeDato(recepcionPagoTP)
    }
}

function errorUpdateRecepcionPagoTP(error) {
    return {
        type: ERROR_UPDATE_RECEPCIONPAGOTP,
        error: error,
    }
}

export function resetUpdateRecepcionPagoTP() {
    return {
        type: RESET_UPDATE_RECEPCIONPAGOTP,
    }
}

export function updateRecepcionPagoTP(recepcionPagoTP) {
    return {
        type: UPDATE_RECEPCIONPAGOTP,
        recepcionPagoTP
    }
}

export function saveUpdateRecepcionPagoTP() {
    return (dispatch, getState) => {
        dispatch(requestUpdateRecepcionPagoTP());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key], getState()[key].update.activo, getState()[key].create.nuevo);
            });

        let recepcionPagoTP = denormalizeDato(getState().recepcionesPagoTP.update.activo, store);

        return recepcionesPagoTPApi.saveUpdate(recepcionPagoTP)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateRecepcionPagoTP(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateRecepcionPagoTP(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try {
                                dispatch(errorUpdateRecepcionesPagoTP(JSON.parse(error.responseJSON.message)));
                            } catch (e) {
                                dispatch(errorUpdateRecepcionesPagoTP(error.responseJSON.message));
                            }
                        else
                            error.json().then((error) => {
                                dispatch(errorUpdateRecepcionPagoTP(JSON.parse(error.message)));
                                if (error.data && error.data.length > 0)
                                    dispatch(receiveUpdateRecepcionPagoTP(error.data));
                            }).catch(() => {
                                dispatch(errorUpdateRecepcionPagoTP(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}

export function deleteUpdateRecepcionPagoTP(recepcionPagoTP) {
    return {
        type: DELETE_UPDATE_RECEPCIONPAGOTP,
        recepcionPagoTP
    }
}

//UPDATE RECEPCIONESPAGOTP
export const REQUEST_UPDATE_RECEPCIONESPAGOTP = "REQUEST_UPDATE_RECEPCIONESPAGOTP";
export const SUCCESS_UPDATE_RECEPCIONESPAGOTP = "SUCCESS_UPDATE_RECEPCIONESPAGOTP";
export const ERROR_UPDATE_RECEPCIONESPAGOTP = "ERROR_UPDATE_RECEPCIONESPAGOTP";
export const RESET_UPDATE_RECEPCIONESPAGOTP = "RESET_UPDATE_RECEPCIONESPAGOTP";

function requestUpdateRecepcionesPagoTP() {
    return {
        type: REQUEST_UPDATE_RECEPCIONESPAGOTP,
    }
}

function receiveUpdateRecepcionesPagoTP(recepcionesPagoTP) {
    return {
        type: SUCCESS_UPDATE_RECEPCIONESPAGOTP,
        receivedAt: Date.now(),
        recepcionesPagoTP: normalizeDatos(recepcionesPagoTP)
    }
}

function errorUpdateRecepcionesPagoTP(error) {
    return {
        type: ERROR_UPDATE_RECEPCIONESPAGOTP,
        error: error,
    }
}

export function resetUpdateRecepcionesPagoTP() {
    return {
        type: RESET_UPDATE_RECEPCIONESPAGOTP,
    }
}

export function saveUpdateRecepcionesPagoTP() {
    return (dispatch, getState) => {
        dispatch(requestUpdateRecepcionesPagoTP());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key], getState()[key].update.activo, getState()[key].create.nuevo);
            });

        let recepcionesPagoTP = getState().recepcionesPagoTP.update.activos.map((idRecepcionPagoTP) => {
            return denormalizeDato(getState().recepcionesPagoTP.update.activo[idRecepcionPagoTP], store);
        });

        return recepcionesPagoTPApi.saveUpdateRecepcionesPagoTP(recepcionesPagoTP)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateRecepcionesPagoTP(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateRecepcionesPagoTP(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try {
                                dispatch(errorUpdateRecepcionesPagoTP(JSON.parse(error.responseJSON.message)));
                            } catch (e) {
                                dispatch(errorUpdateRecepcionesPagoTP(error.responseJSON.message));
                            }
                        else
                            error.json().then((error) => {
                                dispatch(errorUpdateRecepcionesPagoTP(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorUpdateRecepcionesPagoTP(errorMessages.GENERAL_ERROR));
                            });


                        return;
                }
            });
    }
}

//ALTA RECEPCIONPAGOTP
export const CREATE_RECEPCIONPAGOTP = 'CREATE_RECEPCIONPAGOTP';
export const REQUEST_CREATE_RECEPCIONPAGOTP = "REQUEST_CREATE_RECEPCIONPAGOTP";
export const SUCCESS_CREATE_RECEPCIONPAGOTP = "SUCCESS_CREATE_RECEPCIONPAGOTP";
export const ERROR_CREATE_RECEPCIONPAGOTP = "ERROR_CREATE_RECEPCIONPAGOTP";
export const RESET_CREATE_RECEPCIONPAGOTP = "RESET_CREATE_RECEPCIONPAGOTP";
export const DELETE_CREATE_RECEPCIONPAGOTP = "DELETE_CREATE_RECEPCIONPAGOTP";


//ALTA RECEPCIONPAGOTP
function requestCreateRecepcionPagoTP() {
    return {
        type: REQUEST_CREATE_RECEPCIONPAGOTP,
    }
}

function receiveCreateRecepcionPagoTP(recepcionPagoTP) {
    return {
        type: SUCCESS_CREATE_RECEPCIONPAGOTP,
        receivedAt: Date.now(),
        recepcionPagoTP: normalizeDato(recepcionPagoTP)
    }
}

export function errorCreateRecepcionPagoTP(error) {
    return {
        type: ERROR_CREATE_RECEPCIONPAGOTP,
        error: error,
    }
}

export function resetCreateRecepcionPagoTP() {
    return {
        type: RESET_CREATE_RECEPCIONPAGOTP
    }
}

export function createRecepcionPagoTP(recepcionPagoTP) {
    return {
        type: CREATE_RECEPCIONPAGOTP,
        recepcionPagoTP
    }
}

export function deleteCreateRecepcionPagoTP(recepcionPagoTP) {
    return {
        type: DELETE_CREATE_RECEPCIONPAGOTP,
        recepcionPagoTP
    }
}

export function saveCreateRecepcionPagoTP() {
    return (dispatch, getState) => {
        dispatch(requestCreateRecepcionPagoTP());
        let store = {};
        // Object.keys(getState()).forEach(
        //     (key) => {
        //         console.log("key", key);
        //         if (getState()[key].byId)
        //             store[key] = merge({}, getState()[key].byId[key], getState()[key].update.activo, getState()[key].create.nuevo);
        //     });

        let recepcionPagoTP = denormalizeDato(getState().recepcionesPagoTP.create.nuevo, store);

        return recepcionesPagoTPApi.saveCreate(recepcionPagoTP)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateRecepcionPagoTP(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateRecepcionPagoTP(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        error.json()
                            .then((error) => {
                                if (error.message && error.message != "") {
                                    dispatch(errorCreateRecepcionPagoTP(error.message));
                                } else
                                    dispatch(errorCreateRecepcionPagoTP(errorMessages.GENERAL_ERROR));
                                return;
                            })
                }
            });
    }
}

//CREATE RECEPCIONESPAGOTP
export const REQUEST_CREATE_RECEPCIONESPAGOTP = "REQUEST_CREATE_RECEPCIONESPAGOTP";
export const SUCCESS_CREATE_RECEPCIONESPAGOTP = "SUCCESS_CREATE_RECEPCIONESPAGOTP";
export const ERROR_CREATE_RECEPCIONESPAGOTP = "ERROR_CREATE_RECEPCIONESPAGOTP";
export const RESET_CREATE_RECEPCIONESPAGOTP = "RESET_CREATE_RECEPCIONESPAGOTP";

function requestCreateRecepcionesPagoTP() {
    return {
        type: REQUEST_CREATE_RECEPCIONESPAGOTP,
    }
}

function receiveCreateRecepcionesPagoTP(recepcionesPagoTP) {
    return {
        type: SUCCESS_CREATE_RECEPCIONESPAGOTP,
        receivedAt: Date.now(),
        recepcionesPagoTP: normalizeDatos(recepcionesPagoTP)
    }
}

function errorCreateRecepcionesPagoTP(error) {
    return {
        type: ERROR_CREATE_RECEPCIONESPAGOTP,
        error: error,
    }
}

export function resetCreateRecepcionesPagoTP() {
    return {
        type: RESET_CREATE_RECEPCIONESPAGOTP,
    }
}

export function saveCreateRecepcionesPagoTP() {
    return (dispatch, getState) => {
        dispatch(requestCreateRecepcionesPagoTP());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key], getState()[key].update.activo, getState()[key].create.nuevo);
            });

        let recepcionesPagoTP = getState().recepcionesPagoTP.create.nuevos.map((idRecepcionPagoTP) => {
            return denormalizeDato(getState().recepcionesPagoTP.create.nuevo[idRecepcionPagoTP], store);
        });

        return recepcionesPagoTPApi.saveCreateRecepcionesPagoTP(recepcionesPagoTP)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateRecepcionesPagoTP(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateRecepcionesPagoTP(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try {
                                dispatch(errorCreateRecepcionesPagoTP(JSON.parse(error.responseJSON.message)));
                            } catch (e) {
                                dispatch(errorCreateRecepcionesPagoTP(error.responseJSON.message));
                            }
                        else
                            error.json().then((error) => {
                                dispatch(errorCreateRecepcionesPagoTP(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorCreateRecepcionesPagoTP(errorMessages.GENERAL_ERROR));
                            });


                        return;
                }
            });
    }
}

//DELETE RECEPCIONPAGOTP
export const DELETE_RECEPCIONPAGOTP = 'DELETE_RECEPCIONPAGOTP';
export const REQUEST_DELETE_RECEPCIONPAGOTP = "REQUEST_DELETE_RECEPCIONPAGOTP";
export const SUCCESS_DELETE_RECEPCIONPAGOTP = "SUCCESS_DELETE_RECEPCIONPAGOTP";
export const ERROR_DELETE_RECEPCIONPAGOTP = "ERROR_DELETE_RECEPCIONPAGOTP";
export const RESET_DELETE_RECEPCIONPAGOTP = "RESET_DELETE_RECEPCIONPAGOTP";

function requestDeleteRecepcionPagoTP() {
    return {
        type: REQUEST_DELETE_RECEPCIONPAGOTP,
    }
}

function receiveDeleteRecepcionPagoTP(recepcionPagoTP) {
    return {
        type: SUCCESS_DELETE_RECEPCIONPAGOTP,
        receivedAt: Date.now(),
        recepcionPagoTP: normalizeDato(recepcionPagoTP)
    }
}

function errorDeleteRecepcionPagoTP(error) {
    return {
        type: ERROR_DELETE_RECEPCIONPAGOTP,
        error: error,
    }
}

export function resetDeleteRecepcionPagoTP(error) {
    return {
        type: RESET_DELETE_RECEPCIONPAGOTP,
        error: error,
    }
}

export function deleteRecepcionPagoTP(recepcionPagoTP) {
    return {
        type: DELETE_RECEPCIONPAGOTP,
        recepcionPagoTP
    }
}

export function saveDeleteRecepcionPagoTP(recepcionPagoTP) {
    return dispatch => {
        dispatch(requestDeleteRecepcionPagoTP());
        return recepcionesPagoTPApi.saveDelete(recepcionPagoTP)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(resetDeleteRecepcionPagoTP());
                dispatch(receiveDeleteRecepcionPagoTP(data));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorDeleteRecepcionPagoTP(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try {
                                dispatch(errorDeleteRecepcionPagoTP(JSON.parse(error.responseJSON.message)));
                            } catch (e) {
                                dispatch(errorDeleteRecepcionPagoTP(error.responseJSON.message));
                            }
                        else
                            error.json().then((error) => {
                                dispatch(errorDeleteRecepcionPagoTP(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorDeleteRecepcionPagoTP(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}

//PRINT RECEPCIONPAGOTP
export const REQUEST_PRINT_RECEPCIONPAGOTP = "REQUEST_PRINT_RECEPCIONPAGOTP";
export const SUCCESS_PRINT_RECEPCIONPAGOTP = "SUCCESS_PRINT_RECEPCIONPAGOTP";
export const ERROR_PRINT_RECEPCIONPAGOTP = "ERROR_PRINT_RECEPCIONPAGOTP";
export const RESET_PRINT_RECEPCIONPAGOTP = "RESET_PRINT_RECEPCIONPAGOTP";

function requestPrintRecepcionPagoTP() {
    return {
        type: REQUEST_PRINT_RECEPCIONPAGOTP,
    }
}

function receivePrintRecepcionPagoTP(turnos) {
    return {
        type: SUCCESS_PRINT_RECEPCIONPAGOTP,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintRecepcionPagoTP(error) {
    return {
        type: ERROR_PRINT_RECEPCIONPAGOTP,
        error: error,
    }
}

export function resetPrintRecepcionPagoTP() {
    return {
        type: RESET_PRINT_RECEPCIONPAGOTP,
    }
}

export function printRecepcionPagoTP(idRecepcionPagoTP) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintRecepcionPagoTP());
        return recepcionesPagoTPApi.printRecepcionPagoTP(idRecepcionPagoTP)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintRecepcionPagoTP(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintRecepcionPagoTP(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintRecepcionPagoTP(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintRecepcionPagoTP(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintRecepcionPagoTP(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}