import React, {useEffect} from 'react';

import '../../assets/css/CompraExitosa.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretSquareLeft, faMoneyBill} from "@fortawesome/free-solid-svg-icons";
import success from "../../assets/images/estadoCompra/interface.png";
import pending from "../../assets/images/estadoCompra/clock.png";
import failure from "../../assets/images/estadoCompra/failure.png";
import notFound from "../../assets/images/estadoCompra/signs.png";

import history from '../../history';
import {useDispatch, useSelector} from "react-redux";
import {createRecepcionPagoTP, saveCreateRecepcionPagoTP} from "../../actions/RecepcionPagoTPActions";
import {fetchPedido} from "../../actions/PedidoActions";

export default function CompraConfirmada(props) {
    const dispatch = useDispatch();
    //Store
    const pedidos = useSelector(state => state.pedidos);
    const recepcionesPagoTP = useSelector(state => state.recepcionesPagoTP);

    let pedido = pedidos.update.activo;
    let estadoCompraQuery = props.match.params.estado;
    let params = new URLSearchParams(props.location.search);
    let idPedido = params.get("idPedido") ? params.get("idPedido") : "";
    let Answer = params.get("Answer") ? params.get("Answer") : "";

    useEffect(() => {
        window.scrollTo(0, 0);
        if (idPedido && Answer) {
            dispatch(createRecepcionPagoTP({idPedido, Answer}));
            dispatch(saveCreateRecepcionPagoTP());
        }
        if (idPedido) {
            dispatch(fetchPedido(idPedido))
        }
    }, []);

    useEffect(() => {
        if (recepcionesPagoTP.create.error && idPedido) {
            dispatch(fetchPedido(idPedido))
        }
    }, [recepcionesPagoTP.create.error]);

    const estadoCompra = () => {
        switch (estadoCompraQuery) {
            case "success":
                return (
                    <div>
                        <div className="imagen-circular">
                            <img src={success}/>
                        </div>
                        <h2>¡Tu compra está confirmada!</h2>
                        <p>Te mantendremos informado sobre tu pedido al celular registrado.</p>
                    </div>)
                break;
            case "failure":
                return (
                    <div>
                        <div className="imagen-circular">
                            <img src={failure}/>
                        </div>
                        <h2>Tu pago fue rechazado</h2>
                        <p>Intentalo nuevamente haciendo click en el botón "Retomar Pago"</p>
                    </div>)
                break;
            case "pending":
                return (
                    <div>
                        <div className="imagen-circular">
                            <img src={pending}/>
                        </div>
                        <h2>Tu pago está pendiente de aprobación</h2>
                        <p>Te mantendremos informado sobre tu pedido al celular registrado.</p>
                    </div>)
                break;
            default:
                return (
                    <div>
                        <div className="imagen-circular">
                            <img src={notFound}/>
                        </div>
                        <h2>Dirección incorrecta</h2>
                    </div>
                )

        }
    }

    return (
        <section className="compra-exitosa">
            <div className="container-limit">
                {estadoCompra()}
                <div className="container-botones">
                    <button className="encargar" style={{outline: "none"}} onClick={() => {
                        history.push("/");
                    }}>
                        VOLVER <span><FontAwesomeIcon icon={faCaretSquareLeft} color="white"/></span>
                    </button>
                    {
                        estadoCompraQuery !== "success" &&
                        <button style={{outline: "none"}} onClick={() => {
                            window.open(pedido ? pedido.urlPago : "", "_blank");
                        }}>
                            Retomar Pago <span><FontAwesomeIcon icon={faMoneyBill} color="white"/></span>
                        </button>
                    }
                </div>
            </div>
        </section>

    );
}
