//api
import monedasApi from "../api/monedasApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "./AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeMonedas";

//MONEDAS
export const REQUEST_MONEDAS = 'REQUEST_MONEDAS';
export const RECEIVE_MONEDAS = 'RECEIVE_MONEDAS';
export const INVALIDATE_MONEDAS = 'INVALIDATE_MONEDAS';
export const ERROR_MONEDAS = "ERROR_MONEDAS";
export const RESET_MONEDAS = "RESET_MONEDAS";


export function invalidateMonedas() {
    return {
        type: INVALIDATE_MONEDAS
    }
}

function requestMonedas() {
    return {
        type: REQUEST_MONEDAS,
    }
}

function receiveMonedas(json) {
    return {
        type: RECEIVE_MONEDAS,
        monedas: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorMonedas(error) {
    return {
        type: ERROR_MONEDAS,
        error: error,
    }
}

export function resetMonedas() {
    return {
        type: RESET_MONEDAS
    }
}

export function fetchMonedas(filtros) {
    return dispatch => {
        dispatch(requestMonedas());
        return monedasApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveMonedas(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorMonedas(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorMonedas(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchMonedas(state) {
    const monedas = state.monedas.byId;
    if (!monedas) {
        return true
    } else if (monedas.isFetching) {
        return false
    } else {
        return monedas.didInvalidate;
    }
}

export function fetchMonedasIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchMonedas(getState())) {
            return dispatch(fetchMonedas(filtros))
        }
    }
}


//MODEL
export const REQUEST_MONEDA = 'REQUEST_MONEDA';
export const RECEIVE_MONEDA = 'RECEIVE_MONEDA';
export const INVALIDATE_MONEDA = 'INVALIDATE_MONEDA';
export const ERROR_MONEDA = "ERROR_MONEDA";
export const RESET_MONEDA = "RESET_MONEDA";


export function invalidateMoneda() {
    return {
        type: INVALIDATE_MONEDA
    }
}

function requestMoneda() {
    return {
        type: REQUEST_MONEDA,
    }
}

function receiveMoneda(json) {
    return {
        type: RECEIVE_MONEDA,
        moneda: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorMoneda(error) {
    return {
        type: ERROR_MONEDA,
        error: error,
    }
}

export function fetchMoneda(idMoneda) {
    return dispatch => {
        dispatch(requestMoneda());
        return monedasApi.getOne(idMoneda)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveMoneda(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorMoneda(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorMoneda(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//UPDATE MODEL
export const UPDATE_MONEDA = 'UPDATE_MONEDA';
export const REQUEST_UPDATE_MONEDA = "REQUEST_UPDATE_MONEDA";
export const SUCCESS_UPDATE_MONEDA = "SUCCESS_UPDATE_MONEDA";
export const ERROR_UPDATE_MONEDA = "ERROR_UPDATE_MONEDA";
export const RESET_UPDATE_MONEDA = "RESET_UPDATE_MONEDA";

function requestUpdateMoneda() {
    return {
        type: REQUEST_UPDATE_MONEDA,
    }
}

function receiveUpdateMoneda(moneda) {
    return {
        type: SUCCESS_UPDATE_MONEDA,
        receivedAt: Date.now(),
        moneda: normalizeDato(moneda)
    }
}

function errorUpdateMoneda(error) {
    return {
        type: ERROR_UPDATE_MONEDA,
        error: error,
    }
}

export function resetUpdateMoneda() {
    return {
        type: RESET_UPDATE_MONEDA,
    }
}

export function updateMoneda(moneda) {
    return {
        type: UPDATE_MONEDA,
        moneda
    }
}

export function saveUpdateMoneda() {
    return (dispatch, getState) => {
        dispatch(requestUpdateMoneda());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = getState()[key].byId[key];
            });

        let moneda = denormalizeDato(getState().monedas.update.activo, store);

        return monedasApi.saveUpdate(moneda)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateMoneda(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateMoneda(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorUpdateMoneda(JSON.parse(error.responseJSON.message)));
                        else
                            try {
                                error.json().then((error) => {
                                    dispatch(errorUpdateMoneda(JSON.parse(error.message)));
                                    if (error.data && error.data.length > 0)
                                        dispatch(receiveUpdateMoneda(error.data));
                                });
                            } catch {
                                dispatch(errorUpdateMoneda(errorMessages.GENERAL_ERROR));
                            }


                        return;
                }
            });
    }
}

//UPDATE MONEDAS
export const REQUEST_UPDATE_MONEDAS = "REQUEST_UPDATE_MONEDAS";
export const SUCCESS_UPDATE_MONEDAS = "SUCCESS_UPDATE_MONEDAS";
export const ERROR_UPDATE_MONEDAS = "ERROR_UPDATE_MONEDAS";
export const RESET_UPDATE_MONEDAS = "RESET_UPDATE_MONEDAS";

function requestUpdateMonedas() {
    return {
        type: REQUEST_UPDATE_MONEDAS,
    }
}

function receiveUpdateMonedas(monedas) {
    return {
        type: SUCCESS_UPDATE_MONEDAS,
        receivedAt: Date.now(),
        monedas: normalizeDatos(monedas)
    }
}

function errorUpdateMonedas(error) {
    return {
        type: ERROR_UPDATE_MONEDAS,
        error: error,
    }
}

export function resetUpdateMonedas() {
    return {
        type: RESET_UPDATE_MONEDAS,
    }
}

export function saveUpdateMonedas() {
    return (dispatch, getState) => {
        dispatch(requestUpdateMonedas());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = getState()[key].byId[key];
            });

        let monedas = getState().monedas.update.activos.map((idMoneda) => {
            return denormalizeDato(getState().monedas.byId.monedas[idMoneda], store);
        });

        return monedasApi.saveUpdateMonedas(monedas)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateMonedas(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateMonedas(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorUpdateMonedas(JSON.parse(error.responseJSON.message)));
                        else
                            try {
                                error.json().then((error) => {
                                    dispatch(errorUpdateMonedas(JSON.parse(error.message)));
                                });
                            } catch {
                                dispatch(errorUpdateMonedas(errorMessages.GENERAL_ERROR));
                            }


                        return;
                }
            });
    }
}


//ALTA MONEDA
export const CREATE_MONEDA = 'CREATE_MONEDA';
export const REQUEST_CREATE_MONEDA = "REQUEST_CREATE_MONEDA";
export const SUCCESS_CREATE_MONEDA = "SUCCESS_CREATE_MONEDA";
export const ERROR_CREATE_MONEDA = "ERROR_CREATE_MONEDA";
export const RESET_CREATE_MONEDA = "RESET_CREATE_MONEDA";


//ALTA MONEDA
function requestCreateMoneda() {
    return {
        type: REQUEST_CREATE_MONEDA,
    }
}

function receiveCreateMoneda(moneda) {
    return {
        type: SUCCESS_CREATE_MONEDA,
        receivedAt: Date.now(),
        moneda: normalizeDato(moneda)
    }
}

export function errorCreateMoneda(error) {
    return {
        type: ERROR_CREATE_MONEDA,
        error: error,
    }
}

export function resetCreateMoneda() {
    return {
        type: RESET_CREATE_MONEDA
    }
}

export function createMoneda(moneda) {
    return {
        type: CREATE_MONEDA,
        moneda
    }
}

export function saveCreateMoneda() {
    return (dispatch, getState) => {
        dispatch(requestCreateMoneda());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = getState()[key].byId[key];
            });

        let moneda = denormalizeDato(getState().monedas.create.nuevo, store);

        return monedasApi.saveCreate(moneda)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateMoneda(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateMoneda(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorCreateMoneda(JSON.parse(error.responseJSON.message)));
                        else
                            try {
                                error.json().then((error) => {
                                    dispatch(errorCreateMoneda(JSON.parse(error.message)));
                                    if (error.data)
                                        dispatch(receiveCreateMoneda(error.data));
                                });
                            } catch {
                                dispatch(errorCreateMoneda(errorMessages.GENERAL_ERROR));
                            }


                        return;
                }
            });
    }
}

//DELETE MONEDA
export const DELETE_MONEDA = 'DELETE_MONEDA';
export const REQUEST_DELETE_MONEDA = "REQUEST_DELETE_MONEDA";
export const SUCCESS_DELETE_MONEDA = "SUCCESS_DELETE_MONEDA";
export const ERROR_DELETE_MONEDA = "ERROR_DELETE_MONEDA";
export const RESET_DELETE_MONEDA = "RESET_DELETE_MONEDA";

function requestDeleteMoneda() {
    return {
        type: REQUEST_DELETE_MONEDA,
    }
}

function receiveDeleteMoneda(moneda) {
    return {
        type: SUCCESS_DELETE_MONEDA,
        receivedAt: Date.now(),
        moneda: normalizeDato(moneda)
    }
}

function errorDeleteMoneda(error) {
    return {
        type: ERROR_DELETE_MONEDA,
        error: error,
    }
}

export function resetDeleteMoneda(error) {
    return {
        type: RESET_DELETE_MONEDA,
        error: error,
    }
}

export function deleteMoneda(moneda) {
    return {
        type: DELETE_MONEDA,
        moneda
    }
}

export function saveDeleteMoneda(moneda) {
    return dispatch => {
        dispatch(requestDeleteMoneda());
        return monedasApi.saveDelete(moneda)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(resetDeleteMoneda());
                dispatch(receiveDeleteMoneda(data));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorDeleteMoneda(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorDeleteMoneda(JSON.parse(error.responseJSON.message)));
                        else
                            try {
                                error.json().then((error) => {
                                    dispatch(errorDeleteMoneda(JSON.parse(error.message)));
                                });
                            } catch {
                                dispatch(errorDeleteMoneda(errorMessages.GENERAL_ERROR));
                            }


                        return;
                }
            });
    }
}