import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'
//css
import '../../../assets/css/FormaEntrega.css';
//img
import {ReactComponent as Truck} from "../../../assets/images/delivery-truck.svg";
import {ReactComponent as Bag} from "../../../assets/images/shopping-bag.svg";
//components
//constants
import {fetchFormasEntregaIfNeeded} from "../../../actions/FormaEntregaActions";
import * as FormaEntrega from "../../../constants/FormaEntrega";
import Skelleton from "./Skelleton/Skelleton";

var moment = require('moment');

class FormaDeEntrega extends Component {

    componentDidMount() {
        this.props.fetchFormasEntregaIfNeeded();
    }

    handleSetFormaEntrega(idFormaEntrega) {
        let e = {target: {id: "idFormaEntrega", value: idFormaEntrega}};
        this.props.handleChangePedido(e);
        this.props.refFormaEntrega.current.classList.remove("visible");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.formasEntrega.byId.isFetching !== this.props.formasEntrega.byId.isFetching && !this.props.formasEntrega.byId.isFetching
            && !this.props.pedido.idFormaEntrega && this.props.formasEntrega.allIds.length === 1) {
            this.handleSetFormaEntrega(FormaEntrega.ENVIO_A_DOMICILIO_CON_PAGO_EN_DESTINO_ID);
        }
    }

    render() {
        let {disabled, pedido, refFormaEntrega} = this.props;

        return (
            <section className="listado-formaEntrega">
                <h2 className="titulo-seccion subtitulo-checkout">Forma de entrega</h2>
                <p className="mensaje" ref={refFormaEntrega}>Debe seleccionar una forma de entrega</p>
                <div className="container-formaEntrega">
                    {
                        !this.props.formasEntrega.byId.isFetching &&
                        this.props.formasEntrega.allIds.map((idFormaEntrega) => {
                            let formaEntrega = this.props.formasEntrega.byId.formasEntrega[idFormaEntrega];
                            return (
                                <div
                                    key={"fe" + idFormaEntrega}
                                    className={pedido && pedido.idFormaEntrega === idFormaEntrega ? "formaEntrega active" : "formaEntrega"}
                                    onClick={() => this.handleSetFormaEntrega(idFormaEntrega)}>
                                    <div>
                                        {
                                            formaEntrega && !!formaEntrega.desFormaEntrega &&
                                            <div>
                                                {
                                                    idFormaEntrega === FormaEntrega.ENVIO_A_DOMICILIO_CON_PAGO_EN_DESTINO_ID ?
                                                        <div className="img" style={{width: "55px", height: "55px"}}>
                                                            <Truck/>
                                                        </div>
                                                        :
                                                        <div className="img">
                                                            <Bag/>
                                                        </div>
                                                }
                                                <p>{`${formaEntrega.desFormaEntrega}`}</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                    {
                        !!this.props.formasEntrega.byId.isFetching &&
                        <div
                            key={"fe"}
                            className="direccionFrecuente-loading">
                            <Skelleton tipo="direccionEntrega"/>
                        </div>
                    }
                </div>

            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        pedidos: state.pedidos,
        formasEntrega: state.formasEntrega

    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchFormasEntregaIfNeeded: () => {
            dispatch(fetchFormasEntregaIfNeeded())
        },
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormaDeEntrega));
